









import { Component, PropSync, Vue } from 'vue-property-decorator';

@Component({ name: 'Alert' })
export default class Alert extends Vue {
  @PropSync('isOpen', { type: Boolean }) synced_is_open!: boolean;

  @PropSync('typeAlert', { type: String }) type_alert!: string;

  @PropSync('textAlert', { type: String }) text_alert!: string;
}

