/* eslint-disable import/no-cycle */
import BaseLink from './base-link';
import { Context } from './context';
import Injectable from '../dependency_injection/injectable';

@Injectable()
export default class ExecutorLink extends BaseLink {
  next(context: Context) {
    // eslint-disable-next-line no-param-reassign
    context.result = context.service.internalExecute(context.param);
    const event = new CustomEvent('executor_link', { detail: context });
    window.dispatchEvent(event);
    this.nextLink.next(context);
  }
}
