import Injectable from '@/modules/shared/domain/di/injectable';
import { CreateCustomerAgreementRepository }
  from '../../domain/repositories/create-customer-agreement-repository';

@Injectable()
class CreateCustomerAgreementDummyRepository implements CreateCustomerAgreementRepository {
  create(): Promise<void> {
    return Promise.resolve();
  }
}

export default CreateCustomerAgreementDummyRepository;
