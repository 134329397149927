import Injectable from '@/modules/shared/domain/di/injectable';
import { OnboardingSearchStepsRepository }
  from '../../../domain/repositories/onboarding-search-steps-repository';
import { StepEntity } from '../../../domain/entities/step-entity';

@Injectable()
class OnboardingSearchStepsDummyRepository implements OnboardingSearchStepsRepository {
  execute(): Promise<Array<StepEntity>> {
    const step_data = sessionStorage.getItem('steps_data');

    return Promise.resolve(step_data ? JSON.parse(step_data) : []);
  }
}

export default OnboardingSearchStepsDummyRepository;
