import { Container } from 'inversify-props';
import { UserFetchRepository, UserDummyRepository }
  from '@/modules/register/user/infrastructure/repository';
import { RegisterUserCommandService } from '@/modules/register/user/application/commands';
import { UserRepository } from '@/modules/register/user/domain/repository';
import USER_TYPES from '@/modules/register/user/types';

export default function (container: Container) {
  container
    .bind<UserRepository>(USER_TYPES.USER_REPOSITORY)
    .to(
      process.env.VUE_APP_REPOSITORY_MODE === 'dummy'
        ? UserDummyRepository
        : UserFetchRepository,
    )
    .inSingletonScope();

  container
    .bind<RegisterUserCommandService>(USER_TYPES.REGISTER_USER_COMMAND_SERVICE)
    .to(RegisterUserCommandService)
    .inSingletonScope();
}
