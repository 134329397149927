import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import TYPES from '@/types';
import { SuburbRepository } from '../../domain/repository';
import { SuburbInZipCodeEntity } from '../../domain/entities';

@Injectable()
export default class SuburbHttpRepository implements SuburbRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/sepomex/settlement/search?pagination_all_items=true&zip_code=`;

  findInZipCode(zip_code: string): Promise<Array<SuburbInZipCodeEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.path.concat(zip_code))
        .then((response) => {
          response.json().then(({ data, message, success }) => {
            if (success) {
              resolve(data);
            } else {
              reject(new Error(message));
            }
          });
        })
        .catch(reject);
    });
  }
}
