import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import {
  AuthenticationDataFetchRepository,
  AuthenticationDataDummyRepository,
}
  from './infrastructure/repositories';
import {
  JwtHttpRepository,
  JwtDummyRepository,
} from './infrastructure/repositories/jwt-repository';
import VueStateManager from './infrastructure/state/vue-state-manager';
import { PasswordDummyRepository, PasswordHttpRepository } from '@/modules/authentication/infrastructure/repositories/password-repository';

// Application
import { SignInCommand } from './application/commands';
import GetAuthenticationStateService
  from './application/services/get-authentication-state-service';
import CloseSessionWhenTokenExpiresJob from './application/jobs/close-session-when-token-expires-job';
import ValidatePasswordRecoveryCommand from '@/modules/authentication/application/commands/validate-password-recovery-command';
import RecoverPasswordCommand from '@/modules/authentication/application/commands/recover-password-command';
import PasswordChangedStatusSubscription from '@/modules/authentication/application/subscriptions/password-changed-status-subscription';
import ValidatePasswordQuery from '@/modules/authentication/application/queries/validate-password-query';

// Domain
import { AuthenticationDataRepository, JwtRepository } from './domain/repositories';
import { StateManager } from './domain/state/state-manager';
import { PasswordRepository } from '@/modules/authentication/domain/repositories/password-repository';

export default function setupAuthenticationModule(container: Container) {
  const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<StateManager>(TYPES.AUTHENTICATION_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<SignInCommand>(TYPES.SIGN_IN_COMMAND)
    .to(SignInCommand)
    .inSingletonScope();

  container
    .bind<AuthenticationDataRepository>(TYPES.AUTHENTICATION_DATA_REPOSITORY)
    .to(
      process.env.VUE_APP_REPOSITORY_MODE === 'dummy'
        ? AuthenticationDataDummyRepository
        : AuthenticationDataFetchRepository,
    )
    .inSingletonScope();

  container
    .bind<JwtRepository>(TYPES.AUTHENTICATION_JWT_REPOSITORY)
    .to(
      process.env.VUE_APP_REPOSITORY_MODE === 'dummy'
        ? JwtDummyRepository
        : JwtHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetAuthenticationStateService>(TYPES.GET_AUTHENTICATION_STATE_SERVICE)
    .to(GetAuthenticationStateService)
    .inSingletonScope();

  container
    .bind<CloseSessionWhenTokenExpiresJob>(TYPES.CLOSE_SESSION_WHEN_TOKEN_EXPIRES_JOB)
    .to(CloseSessionWhenTokenExpiresJob)
    .inSingletonScope();

  container
    .bind<PasswordRepository>(TYPES.PASSWORD_REPOSITORY)
    .to(
      with_dummies
        ? PasswordDummyRepository
        : PasswordHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<ValidatePasswordRecoveryCommand>(TYPES.VALIDATE_PASSWORD_RECOVERY_COMMAND)
    .to(ValidatePasswordRecoveryCommand)
    .inSingletonScope();

  container
    .bind<RecoverPasswordCommand>(TYPES.RECOVER_PASSWORD_COMMAND)
    .to(RecoverPasswordCommand)
    .inSingletonScope();

  container
    .bind<ValidatePasswordQuery>(TYPES.VALIDATE_PASSWORD_QUERY)
    .to(ValidatePasswordQuery)
    .inSingletonScope();

  container
    .bind<PasswordChangedStatusSubscription>(TYPES.PASSWORD_CHANGED_STATUS_SUBSCRIPTION)
    .to(PasswordChangedStatusSubscription)
    .inSingletonScope();
}
