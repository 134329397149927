import TYPES from '../../types';

// DOMAIN
import { MaritalStatusEntity } from '@/modules/onboarding/catalogs/marital-status/domain/entities/marital-status-entity';
import { MaritalStatusRepository } from '@/modules/onboarding/catalogs/marital-status/domain/repositories/marital-status-repository';
import { MaritalStatusStateManager } from '@/modules/onboarding/catalogs/marital-status/domain/state/marital-status-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetMaritalStatusesQuery extends Query<Array<MaritalStatusEntity>> {
  @Inject(TYPES.MARITAL_STATUS_REPOSITORY)
  readonly repository!: MaritalStatusRepository;

  @Inject(TYPES.MARITAL_STATUS_STATE_MANAGER)
  readonly state_manager!: MaritalStatusStateManager;

  internalExecute(): Array<MaritalStatusEntity> {
    const state_manager_loaded = this.state_manager.state.loaded;

    if (!state_manager_loaded) {
      this.repository
        .getMaritalStatusItems()
        .then((items) => {
          this.state_manager.patch({ items });
        });
    }

    return this.state_manager.state.items;
  }
}
