import Vue from 'vue';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';
import { PhoneRepositoryStateManager as StateManager } from '../../domain/state/state-manager';
import State from '../../domain/state/state';

@Injectable()
export default class VueStateManager implements StateManager {
  private _state: State = Vue.observable<State>(new State());

  private readonly observers: Observer[] = [];

  get state(): State {
    return this._state;
  }

  set state(value: State) {
    this._state = value;
    this.notifyAll();
  }

  patch(new_state: Partial<State>) {
    if (new_state.items) {
      this.state.loaded = true;
      this.state.items = new_state.items;
    }
  }

  notifyAll() {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer) {
    this.observers.push(observer);
  }
}
