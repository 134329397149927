import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Query from '@/modules/shared/domain/services/query';
import { StateRepository } from '../../domain/repository/state-repository';
import { StateEntity } from '../../domain/entities/state-entity';
import { StateManager } from '../../domain/state/state-manager';

@Injectable()
export default class GetStatesQueryService extends Query<Array<StateEntity>> {
  @Inject(TYPES.ONBOARDING_CATALOG_STATE_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.STATE_REPOSITORY)
  private readonly repository!: StateRepository

  internalExecute(country_id: number) {
    if (this.state_manager.state.items.length < 1) {
      this.repository.findAll(country_id)
        .then((items) => {
          this.state_manager.patch({ items });
        });
    }

    return this.state_manager.state.items;
  }
}
