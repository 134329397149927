import { CreateDocumentDto } from '../dtos/create-document-dto';

export enum DocumentTypeEnum {
  BANK_ACCOUNT = 'bank-account',
  PROOF_OF_ADDRESS = 'proof-of-address',
  RFC = 'rfc',
  INITIAL_CONTRIBUTION_PROOF = 'initial-contribution-proof',
}

export interface CreateDocumentRepository {
  execute(document: CreateDocumentDto, document_type: DocumentTypeEnum): Promise<void>;
}
