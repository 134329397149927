import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import authorizeAccess from './security/access';
import unauthenticatedRoutes from './unauthenticated';
import authenticatedRoutes from './authenticated';
import loginRoute from './login';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  ...unauthenticatedRoutes,
  ...authenticatedRoutes,
  loginRoute,
  {
    path: '/',
    redirect: '/sign-up',
  },
  {
    path: '*',
    component: () => import('@/vue-app/layouts/Layout.vue'),
    children: [
      {
        path: '/',
        name: 'PageNotFound',
        component: () => import('@/vue-app/views/NotFound.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(authorizeAccess);

export default router;
