import { Container } from 'inversify-props';
import TYPES from './types';
import CreateBeneficiariesDummyRepository
  from './infrastructure/repositories/create-beneficiaries-dummy-repository';
import CreateBeneficiariesHttpRepository
  from './infrastructure/repositories/create-beneficiaries-http-repository';
import CreateBeneficiariesCommand from './application/commands/create-beneficiaries-command';
import { CreateBeneficiariesRepository } from './domain/repositories/create-beneficiaries-repository';

const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

export default function setupBeneficiary(container: Container) {
  container
    .bind<CreateBeneficiariesRepository>(TYPES.ONBOARDING_CREATE_BENEFICIARIES_REPOSITORY)
    .to(
      withDummies
        ? CreateBeneficiariesDummyRepository
        : CreateBeneficiariesHttpRepository,
    ).inSingletonScope();

  container
    .bind<CreateBeneficiariesCommand>(TYPES.ONBOARDING_CREATE_BENEFICIARIES_COMMAND)
    .to(CreateBeneficiariesCommand)
    .inSingletonScope();
}
