import { Container } from 'inversify-props';
import TYPES from './types';
import VueStateManager from './infrastructure/state/vue-state-manager';
import { StateFetchRepository } from './infrastructure/repository';
import { GetStateQueryService } from './application/queries';
import { StateManager } from './domain/state/state-manager';
import { StateRepository } from './domain/repository';

export default function setupState(container: Container) {
  container
    .bind<StateManager>(TYPES.ONBOARDING_CATALOG_STATE_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<StateRepository>(TYPES.STATE_REPOSITORY)
    .to(StateFetchRepository)
    .inSingletonScope();

  container
    .bind<GetStateQueryService>(TYPES.GET_STATES_QUERY_SERVICE)
    .to(GetStateQueryService)
    .inSingletonScope();
}
