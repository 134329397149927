import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Query from '@/modules/shared/domain/services/query';
import TYPES from '../../types';
import { SearchPersonRepository } from '../../domain/repository/search-person-repository';
import { StateManager } from '../../domain/state/state-manager';
import { PersonEntity } from '../../domain/entities/person-entity';

@Injectable()
export default class GetPersonQueryService extends Query<PersonEntity> {
  @Inject(TYPES.ONBOARDING_PERSON_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.SEARCH_PERSON_REPOSITORY)
  private readonly person_repository!: SearchPersonRepository;

  internalExecute(): PersonEntity {
    if (this.state_manager.state.id === '') {
      this.person_repository.get()
        .then((person) => this.state_manager.patch(person));
    }

    return this.state_manager.state;
  }
}
