import { Container } from 'inversify-props';
import TYPES from './types';
import OnboardingCreateStepDummyRepository
  from './infrastructure/repositories/onboarding-create-step-repository/onboarding-create-step-dummy-repository';
import { OnboardingCreateStepHttpRepository }
  from './infrastructure/repositories/onboarding-create-step-repository/onboarding-create-step-http-repository';
import OnboardingUpdateStepDummyRepository
  from './infrastructure/repositories/onboarding-update-step-repository/onboarding-update-step-dummy-repository';
import OnboardingUpdateStepHttpRepository
  from './infrastructure/repositories/onboarding-update-step-repository/onboarding-update-step-http-repository';
import OnboardingSearchStepsDummyRepository
  from './infrastructure/repositories/onboarding-search-steps-repository/onboarding-search-steps-dummy-repository';
import OnboardingSearchStepsHttpRepository
  from './infrastructure/repositories/onboarding-search-steps-repository/onboarding-search-steps-http-repository';
import VueStateManager from './infrastructure/state/vue-state-manager';
import CreateOnboardingStepCommand
  from '@/modules/onboarding/status/application/commands/create-onboarding-step-command';
import UpdateOnboardingStepCommand from './application/commands/update-onboarding-step-command';
import GetOnboardingStepQuery from './application/queries/get-onboarding-step-query';
import GetOnboardingStepName from './application/services/get-onboarding-step-name';
import CompleteOnBoardingService from './application/services/complete-on-boarding';
import OnBoardingSearchStepsQuery from './application/queries/onboarding-search-steps-query';
import { OnboardingCreateStepRepository } from './domain/repositories/onboarding-create-step-repository';
import { OnboardingUpdateStepRepository } from './domain/repositories/onboarding-update-step-repository';
import { OnboardingSearchStepsRepository } from './domain/repositories/onboarding-search-steps-repository';
import { StateManager } from './domain/state/state-manager';

const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

export default function setupStatus(container: Container) {
  container
    .bind<StateManager>(TYPES.ONBOARDING_STATUS_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<OnboardingSearchStepsRepository>(TYPES.ONBOARDING_SEARCH_STEPS_REPOSITORY)
    .to(
      withDummies
        ? OnboardingSearchStepsDummyRepository
        : OnboardingSearchStepsHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<OnboardingCreateStepRepository>(TYPES.ONBOARDING_CREATE_STEP_REPOSITORY)
    .to(
      withDummies
        ? OnboardingCreateStepDummyRepository
        : OnboardingCreateStepHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<CreateOnboardingStepCommand>(TYPES.CREATE_ONBOARDING_STEP_COMMAND)
    .to(CreateOnboardingStepCommand)
    .inSingletonScope();

  container
    .bind<OnboardingUpdateStepRepository>(TYPES.ONBOARDING_UPDATE_STEP_REPOSITORY)
    .to(
      withDummies
        ? OnboardingUpdateStepDummyRepository
        : OnboardingUpdateStepHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<UpdateOnboardingStepCommand>(TYPES.UPDATE_ONBOARDING_STEP_COMMAND)
    .to(UpdateOnboardingStepCommand)
    .inSingletonScope();

  container
    .bind<GetOnboardingStepQuery>(TYPES.GET_ONBOARDING_STEP_QUERY)
    .to(GetOnboardingStepQuery)
    .inSingletonScope();

  container
    .bind<GetOnboardingStepName>(TYPES.GET_ONBOARDING_STEP_NAME)
    .to(GetOnboardingStepName)
    .inSingletonScope();

  container
    .bind<CompleteOnBoardingService>(TYPES.COMPLETE_ONBOARDING_SERVICE)
    .to(CompleteOnBoardingService)
    .inSingletonScope();

  container
    .bind<OnBoardingSearchStepsQuery>(TYPES.ONBOARDING_SEARCH_STEPS_QUERY)
    .to(OnBoardingSearchStepsQuery)
    .inSingletonScope();
}
