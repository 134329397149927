import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Query from '@/modules/shared/domain/services/query';
import { SuburbRepository } from '../../domain/repository';
import { SuburbInZipCodeEntity } from '../../domain/entities';
import { SuburbsInZipCodeStateManager as StateManager } from '../../domain/state-manager/suburbs-in-zip-code';
import TYPES from '../../types';

@Injectable()
export default class GetSuburbsInZipCodeQuery extends Query<Array<SuburbInZipCodeEntity>, string> {
  @Inject(TYPES.SUBURBS_IN_ZIP_CODE_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.SUBURB_REPOSITORY)
  private readonly repository!: SuburbRepository;

  internalExecute(zip_code: string) {
    if (this.state_manager.state.zip_code !== zip_code) {
      this.repository.findInZipCode(zip_code).then((suburbs) => {
        this.state_manager.patch({ zip_code, suburbs });
      });
    }

    return this.state_manager.state.suburbs;
  }
}
