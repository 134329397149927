














import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'NotificationGroups',
})
export default class NotificationGroups extends Vue {
}

