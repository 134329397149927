import TYPES from '@/types';

// DOMAIN
import { CustomerDocumentStateManager } from '@/modules/onboarding/customer-document/domain/state-manager';
import { CustomerDocumentRepository } from '@/modules/onboarding/customer-document/domain/repositories';
import Query from '@/modules/shared/domain/use-cases/query';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class GetCustomerBase64DocumentQuery extends Query<Promise<string>, string> {
  @Inject(TYPES.ONBOARDING_CUSTOMER_DOCUMENT_REPOSITORY)
  private readonly repository!: CustomerDocumentRepository;

  @Inject(TYPES.ONBOARDING_CUSTOMER_DOCUMENT_STATE_MANAGER)
  private readonly state_manager!: CustomerDocumentStateManager;

  internalExecute(customer_document_id: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const current_document = this.state_manager.state.current_customer_documents.find(
        (document) => document.id === customer_document_id,
      );
      if (current_document) {
        resolve(current_document.base64);
      } else {
        this.repository
          .downloadBase64CustomerDocument(customer_document_id)
          .then((base64) => {
            this.state_manager.addBase64ToState({
              id: customer_document_id,
              base64,
            });
            resolve(base64);
          })
          .catch(reject);
      }
    });
  }
}
