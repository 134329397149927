import { Container } from 'inversify-props';
import { setupIdentityVerificationModule } from './identity-verification';
import setupCatalogs from './catalogs/setup';
import setupPhone from './phone/setup';
import setupStatus from './status/setup';
import setupAddress from './address/setup';
import setupPerson from './person/setup';
import setupDocuments from './documents/setup';
import setupCustomerEmail from './customer-email/setup';
import setupBeneficiary from './beneficiary/setup';
import setupBankAccountNumber from './bank-account-number/setup';
import setupCustomerAgreement from './customer-agreement/setup';
import setupExpectedTransactionality from './expected-transactionality/setup';
import setupOnBoarding from './on-boarding/setup';
import setupCustomerGeolocations from './customer-geolocations/setup';
import setupInterviews from './interviews/setup';
import setupEmail from './email/setup';
import setupCustomerDocumentModule from './customer-document/setup';
import setupProofOfAddressModule from '@/modules/onboarding/proof-of-address/setup';

export default function get_container(container: Container) {
  setupCatalogs(container);
  setupIdentityVerificationModule(container);
  setupPhone(container);
  setupStatus(container);
  setupAddress(container);
  setupPerson(container);
  setupDocuments(container);
  setupCustomerEmail(container);
  setupBeneficiary(container);
  setupBankAccountNumber(container);
  setupCustomerAgreement(container);
  setupExpectedTransactionality(container);
  setupOnBoarding(container);
  setupCustomerGeolocations(container);
  setupInterviews(container);
  setupEmail(container);
  setupCustomerDocumentModule(container);
  setupProofOfAddressModule(container);
}
