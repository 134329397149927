import TYPES from '@/modules/shared/types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import { InterviewsRepository } from '../../domain/repositories/interviews-repository';
import { CreateInterviewDto } from '../../domain/data_transfer_objects/create_interview_dto';

@Injectable()
export default class InterviewsHttpRepository implements InterviewsRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  create(create_interview_dto: CreateInterviewDto): Promise<void> {
    const path = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/pld-interviews/`;

    return new Promise((resolve, reject) => {
      this.http_connector.execute(path, 'POST', create_interview_dto)
        .then((response) => {
          response.json()
            .then(({ success, message }) => {
              if (success) {
                resolve();
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
