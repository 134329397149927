import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import TYPES from '@/types';
import { SearchCellPhonesRepository } from '../../../domain/repository/search-cell-phones-repository';
import { CustomerCellphoneEntity } from '../../../domain/entities/customer-cellphone-entity';

@Injectable()
export default class SearchCellPhonesHttpRepository implements SearchCellPhonesRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/cellphones/`;

  findAll(): Promise<Array<CustomerCellphoneEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.path)
        .then((response) => {
          response.json().then(({ data, message, success }) => {
            if (success) {
              resolve(data);
            } else {
              reject(new Error(message));
            }
          });
        })
        .catch(reject);
    });
  }
}
