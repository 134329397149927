import { GeolocationReference } from '../entities/geolocation-reference';

export default class State {
  current_position = {
    latitude: 0,
    longitude: 0,
  };

  references = {
    items: [] as Array<GeolocationReference>,
    loaded: false,
  };

  is_geolocation_active = false;
}
