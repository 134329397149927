import Injectable from '@/modules/shared/domain/di/injectable';
import { CreateBankAccountNumberRepository }
  from '../../../domain/repositories/create-bank-account-number-repository';

@Injectable()
class CreateBankAccountNumberDummyRepository implements CreateBankAccountNumberRepository {
  create(): Promise<void> {
    return Promise.resolve();
  }
}

export default CreateBankAccountNumberDummyRepository;
