import TYPES from '../../types';

// Domain
import { EmailRepository } from '../../domain/repositories/email-repository';
import Query from '@/modules/shared/domain/use-cases/query';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class OnBoardingVerifyEmailExistenceQuery extends Query<Promise<boolean>, string> {
  @Inject(TYPES.ON_BOARDING_EMAIL_REPOSITORY)
  private readonly repository!: EmailRepository;

  internalExecute(email: string): Promise<boolean> {
    return this.repository.exists(email);
  }
}
