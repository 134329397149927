import Injectable from '../../../domain/dependency_injection/injectable';
import { HttpConnector } from '../../../domain/connectors/http';

@Injectable()
export default class FetchHttpConnector implements HttpConnector {
  async execute(
    path: string,
    method = 'GET',
    body = undefined,
    retries = 3,
    headers = undefined,
  ): Promise<Response> {
    try {
      const response = await fetch(path, {
        method,
        headers: headers || {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('authorization')}` || '',
        },
        body: JSON.stringify(body),
      });

      if (!response.ok && retries > 0) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(this.execute(path, method, body, retries - 1));
          }, 1000);
        });
      }

      return response;
    } catch {
      if (retries > 0) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(this.execute(path, method, body, retries - 1));
          }, 1000);
        });
      }

      return Promise.reject(new Error('Fetch Error'));
    }
  }
}
