import { Container } from 'inversify-props';
import TYPES from './types';

// INFRASTRUCTURE
import PromotorVueStateManager from '@/modules/onboarding/catalogs/promotor/infrastructure/state/promotor-vue-state-manager';
import PromotorDummyRepository from '@/modules/onboarding/catalogs/promotor/infrastructure/repositories/promotor-dummy-repository';
import PromotorHttpRepository from '@/modules/onboarding/catalogs/promotor/infrastructure/repositories/promotor-http-repository';

// APPLICATION
import SearchPromotorQuery from '@/modules/onboarding/catalogs/promotor/application/queries/search-promotor-query';

// DOMAIN
import { PromotorRepository } from '@/modules/onboarding/catalogs/promotor/domain/repositories/promotor-repository';
import { PromotorStateManager } from '@/modules/onboarding/catalogs/promotor/domain/state/promotor-state-manager';

export default function setupPromotorModule(container: Container) {
  const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<PromotorRepository>(TYPES.PROMOTOR_REPOSITORY)
    .to(
      with_dummies
        ? PromotorDummyRepository
        : PromotorHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchPromotorQuery>(TYPES.SEARCH_PROMOTOR_QUERY)
    .to(SearchPromotorQuery)
    .inSingletonScope();

  container
    .bind<PromotorStateManager>(TYPES.PROMOTOR_STATE_MANAGER)
    .to(PromotorVueStateManager)
    .inSingletonScope();
}
