import { EconomicActivityEntity } from '@/modules/onboarding/catalogs/economic-activity/domain/entities/economic-activity-entity';

export const sortEconomicActivities = (
  economic_activities: Array<EconomicActivityEntity>,
): Array<EconomicActivityEntity> => economic_activities
  .slice()
  .sort((a, b) => {
    if (a.value > b.value) {
      return 1;
    }
    if (a.value < b.value) {
      return -1;
    }

    return 0;
  });
