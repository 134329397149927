import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import { PhoneEntity } from '../../domain/entities/phone-entity';
import { UpdatePhoneRepository } from '../../domain/repository/update-phone-repository';
import { PhoneRepositoryStateManager as StateManager } from '../../domain/state/state-manager';

@Injectable()
export default class UpdatePhoneCommand extends Command<PhoneEntity> {
  @Inject(TYPES.UPDATE_PHONE_REPOSITORY)
  private readonly repository!: UpdatePhoneRepository;

  @Inject(TYPES.ONBOARDING_PHONE_REPOSITORY_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  internalExecute(phone: PhoneEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.repository.update(phone)
        .then(() => {
          const { phones } = this.state_manager.state;
          const phoneIndex = phones.findIndex((item) => item.phone.id === phone.id);
          phones[phoneIndex].phone = phone;
          this.state_manager.patch({ phones });
          resolve();
        })
        .catch(reject);
    });
  }
}
