import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import TYPES from '@/types';
import { UserDto } from '../../domain/dtos/user-dto';
import { UserRepository } from '../../domain/repository/user-repository';

@Injectable()
export default class UserFetchRepository implements UserRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/registry/`;

  create(user: UserDto): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.path, 'POST', {
        id: user.id,
        group_id: user.group_id,
        username: user.username,
        password: user.password,
        password_confirmation: user.password_confirmation,
      }, 0,
      {
        'Content-Type': 'application/json',
        'X-CAPTCHA': user.token,
      })
        .then((response) => {
          response.json().then(({ success, message }) => {
            if (success) {
              resolve();
            } else {
              reject(new Error(message));
            }
          });
        })
        .catch(reject);
    });
  }
}
