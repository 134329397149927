import SHARED_TYPES from '@/modules/shared/types';
import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Subscription from '@/modules/shared/domain/use-cases/subscription';
import { Permissions } from '@/modules/shared/domain/permissions';
import { StateManager } from '../../domain/state/state-manager';

@Injectable()
export default class PermissionsStatusSubscription extends Subscription {
  @Inject(SHARED_TYPES.PERMISSIONS)
  private readonly permissions!: Permissions;

  @Inject(TYPES.PERMISSIONS_STATUS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  private intervalFunction: any;

  internalExecute() {
    if (!this.intervalFunction) {
      this.intervalFunction = setInterval(() => {
        this.permissions.getCurrentPermissions()
          .then((permissions_status) => {
            this.state_manager.patch(permissions_status);
          });
      }, 1000);
    }
  }

  close() {
    if (this.intervalFunction) {
      clearInterval(this.intervalFunction);
      this.intervalFunction = null;
    }
  }
}
