import TYPES from '@/types';

// DOMAIN
import { ProofOfAddressRepository } from '@/modules/onboarding/proof-of-address/domain/repositories/proof-of-address-repository';
import Command from '@/modules/shared/domain/use-cases/command';
import Inject from '@/modules/shared/domain/di/inject';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class UseIneAsProofOfAddressCommand extends Command<string> {
  @Inject(TYPES.PROOF_OF_ADDRESS_REPOSITORY)
  private readonly repository!: ProofOfAddressRepository;

  internalExecute(id: string): Promise<void> {
    return this.repository.useIneAsProofOfAddress(id);
  }
}
