import TYPES from '@/modules/shared/types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import { GeolocationReferencesRepository } from '../../../domain/repositories/geolocation-references-repository';
import { GeolocationReference } from '../../../domain/entities/geolocation-reference';

@Injectable()
class GeolocationReferencesHttpRepository implements GeolocationReferencesRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  search(): Promise<Array<GeolocationReference>> {
    const path = `${process.env.VUE_APP_API_URL}/api/v1/app/geolocation-references/`;

    return new Promise((resolve, reject) => {
      this.http_connector.execute(path)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}

export default GeolocationReferencesHttpRepository;
