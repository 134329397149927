import Injectable from '@/modules/shared/domain/di/injectable';
import { JwtRepository } from '../../../domain/repositories/jwt-repository';

@Injectable()
export default class JwtDummyRepository implements JwtRepository {
  create(token?: string): Promise<boolean> {
    const auth_token = token || sessionStorage.getItem('authorization');

    if (auth_token !== 'test') {
      return Promise.reject(new Error('Invalid token'));
    }

    return Promise.resolve(!!auth_token);
  }
}
