/* eslint-disable max-len */

import Injectable from '@modules/shared/domain/di/injectable';
import { IdentityVerificationServiceRepository } from '@/modules/onboarding/identity-verification/domain/repositories';
import { IdentityVerificationServiceType } from '../../domain/types';

@Injectable()
export default class IdentityVerificationServiceDummyRepository implements IdentityVerificationServiceRepository {
  private readonly identity_verification_service: IdentityVerificationServiceType = {
    id: '1',
    name: 'mati',
    flow_id: '1',
  };

  get(): Promise<IdentityVerificationServiceType> {
    return Promise.resolve(this.identity_verification_service);
  }
}
