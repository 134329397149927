import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import { RenewVerifyNipRepository } from '@/modules/renew-verify-nip/domain/repository';

@Injectable()
export default class RenewVerifyNipDummyRepository implements RenewVerifyNipRepository {
  create(): Promise<any> {
    return Promise.resolve({
      success: true,
      message: 'All Ok',
      errors: '',
    });
  }
}
