import { Container } from 'inversify-props';
import TYPES from './types';
import SearchPhonesDummyRepository
  from './infrastructure/repository/search-phones-repository/search-phones-dummy-repository';
import SearchPhonesHttpRepository
  from './infrastructure/repository/search-phones-repository/search-phones-http-repository';
import SearchCellPhonesDummyRepository
  from './infrastructure/repository/search-cell-phones-repository/search-cell-phones-dummy-repository';
import SearchCellPhonesHttpRepository
  from './infrastructure/repository/search-cell-phones-repository/search-cell-phones-http-repository';
import CreatePhoneDummyRepository
  from './infrastructure/repository/create-phone-repository/create-phone-dummy-repository';
import CreatePhoneHttpRepository
  from './infrastructure/repository/create-phone-repository/create-phone-http-repository';
import UpdatePhoneDummyRepository
  from './infrastructure/repository/update-phone-repository/update-phone-dummy-repository';
import UpdatePhoneHttpRepository
  from './infrastructure/repository/update-phone-repository/update-phone-http-repository';
import CreateCellPhoneDummyRepository
  from './infrastructure/repository/create-cell-phone-repository/create-cell-phone-dummy-repository';
import CreateCellPhoneHttpRepository
  from './infrastructure/repository/create-cell-phone-repository/create-cell-phone-http-repository';
import UpdateCellPhoneDummyRepository
  from './infrastructure/repository/update-cell-phone-repository/update-cell-phone-dummy-repository';
import UpdateCellPhoneHttpRepository
  from './infrastructure/repository/update-cell-phone-repository/update-cell-phone-http-repository';
import PhoneRepositoryVueStateManager from './infrastructure/state/phone-repository-vue-state-manager';
import { GetPhonesQueryService, GetCellPhonesQuery } from './application/queries';
import CreatePhoneCommand from './application/commands/create-phone-command';
import CreateOrUpdatePhoneService from './application/services/create-or-update-phone-service';
import UpdatePhoneCommand from './application/commands/update-phone-command';
import CreateCellPhoneCommand from './application/commands/create-cell-phone-command';
import UpdateCellPhoneCommand from './application/commands/update-cell-phone-command';
import CreateOrUpdateCellPhoneService from './application/services/create-or-update-cell-phone-service';
import { PhoneRepositoryStateManager } from './domain/state/state-manager';
import { SearchPhonesRepository } from './domain/repository/search-phones-repository';
import { SearchCellPhonesRepository } from './domain/repository/search-cell-phones-repository';
import { CreatePhoneRepository } from './domain/repository/create-phone-repository';
import { UpdatePhoneRepository } from './domain/repository/update-phone-repository';
import { UpdateCellPhoneRepository } from './domain/repository/update-cell-phone-repository';
import { CreateCellPhoneRepository } from './domain/repository/create-cell-phone-repository';

export default function setupPhone(container: Container) {
  container
    .bind<SearchPhonesRepository>(TYPES.SEARCH_PHONES_REPOSITORY)
    .to(
      process.env.VUE_APP_REPOSITORY_MODE === 'dummy'
        ? SearchPhonesDummyRepository
        : SearchPhonesHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<PhoneRepositoryStateManager>(TYPES.ONBOARDING_PHONE_REPOSITORY_STATE_MANAGER)
    .to(PhoneRepositoryVueStateManager)
    .inSingletonScope();

  container
    .bind<GetPhonesQueryService>(TYPES.GET_PHONES_QUERY_SERVICE)
    .to(GetPhonesQueryService)
    .inSingletonScope();

  container
    .bind<SearchCellPhonesRepository>(TYPES.SEARCH_CELL_PHONES_REPOSITORY)
    .to(
      process.env.VUE_APP_REPOSITORY_MODE === 'dummy'
        ? SearchCellPhonesDummyRepository
        : SearchCellPhonesHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetCellPhonesQuery>(TYPES.GET_CELL_PHONES_QUERY)
    .to(GetCellPhonesQuery)
    .inSingletonScope();

  container
    .bind<CreatePhoneRepository>(TYPES.CREATE_PHONE_REPOSITORY)
    .to(
      process.env.VUE_APP_REPOSITORY_MODE === 'dummy'
        ? CreatePhoneDummyRepository
        : CreatePhoneHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<CreatePhoneCommand>(TYPES.CREATE_PHONE_COMMAND)
    .to(CreatePhoneCommand)
    .inSingletonScope();

  container
    .bind<CreateOrUpdatePhoneService>(TYPES.CREATE_OR_UPDATE_PHONE_SERVICE)
    .to(CreateOrUpdatePhoneService)
    .inSingletonScope();

  container
    .bind<UpdatePhoneRepository>(TYPES.UPDATE_PHONE_REPOSITORY)
    .to(
      process.env.VUE_APP_REPOSITORY_MODE === 'dummy'
        ? UpdatePhoneDummyRepository
        : UpdatePhoneHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<UpdatePhoneCommand>(TYPES.UPDATE_PHONE_COMMAND)
    .to(UpdatePhoneCommand)
    .inSingletonScope();

  container
    .bind<CreateCellPhoneRepository>(TYPES.CREATE_CELL_PHONE_REPOSITORY)
    .to(
      process.env.VUE_APP_REPOSITORY_MODE === 'dummy'
        ? CreateCellPhoneDummyRepository
        : CreateCellPhoneHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<UpdateCellPhoneRepository>(TYPES.UPDATE_CELL_PHONE_REPOSITORY)
    .to(
      process.env.VUE_APP_REPOSITORY_MODE === 'dummy'
        ? UpdateCellPhoneDummyRepository
        : UpdateCellPhoneHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<CreateCellPhoneCommand>(TYPES.CREATE_CELL_PHONE_COMMAND)
    .to(CreateCellPhoneCommand)
    .inSingletonScope();

  container
    .bind<UpdateCellPhoneCommand>(TYPES.UPDATE_CELL_PHONE_COMMAND)
    .to(UpdateCellPhoneCommand)
    .inSingletonScope();

  container
    .bind<CreateOrUpdateCellPhoneService>(TYPES.CREATE_OR_UPDATE_CELL_PHONE_SERVICE)
    .to(CreateOrUpdateCellPhoneService)
    .inSingletonScope();
}
