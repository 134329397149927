import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import { BeneficiaryEntity } from '../../domain/types/beneficiary-entity';
import { CreateBeneficiariesRepository } from '../../domain/repositories/create-beneficiaries-repository';

@Injectable()
export default class CreateBeneficiariesCommand extends Command<Array<BeneficiaryEntity>> {
  @Inject(TYPES.ONBOARDING_CREATE_BENEFICIARIES_REPOSITORY)
  private readonly repository!: CreateBeneficiariesRepository;

  internalExecute(beneficiaries: Array<BeneficiaryEntity>): Promise<unknown> {
    return this.repository.create(beneficiaries);
  }
}
