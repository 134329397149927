import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import SearchEmailDummyRepository
  from './infrastructure/repository/customer-email-dummy-repository';
import CustomerEmailHttpRepository
  from './infrastructure/repository/customer-email-http-repository';

// Application
import OnBoardingSearchCustomerEmailsQuery from './application/queries/on-boarding-search-customer-emails-query';

// Domain
import { StateManager } from './domain/state/state-manager';
import { CustomerEmailRepository } from './domain/repository/customer-email-repository';

export default function setupCustomerEmail(container: Container) {
  const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<StateManager>(TYPES.ON_BOARDING_CUSTOMER_EMAIL_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<CustomerEmailRepository>(TYPES.ON_BOARDING_CUSTOMER_EMAIL_REPOSITORY)
    .to(
      with_dummies
        ? SearchEmailDummyRepository
        : CustomerEmailHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<OnBoardingSearchCustomerEmailsQuery>(TYPES.ON_BOARDING_SEARCH_CUSTOMER_EMAILS_QUERY)
    .to(OnBoardingSearchCustomerEmailsQuery)
    .inSingletonScope();
}
