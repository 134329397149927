import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Query from '@/modules/shared/domain/services/query';
import TYPES from '../../types';
import { StateManager } from '../../domain/state/state-manager';
import { ApproximateValueRepository } from '../../domain/repository/approximate-value-repository';
import { ApproximateValueEntity } from '../../domain/entities/approximate-value-entity';

@Injectable()
export default class GetAproximateValuesQueryService extends Query<Array<ApproximateValueEntity>> {
  @Inject(TYPES.APPROXIMATE_VALUE_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.APPROXIMATE_VALUE_REPOSITORY)
  private readonly repository!: ApproximateValueRepository;

  internalExecute() {
    if (this.state_manager.state.items.length < 1) {
      this.repository.findAll()
        .then((items) => {
          this.state_manager.patch({ items: items.filter((item) => item.type === 'INGRESO') });
        });
    }

    return this.state_manager.state.items;
  }
}
