import { Container } from 'inversify-props';
import TYPES from '@/modules/onboarding/address/types';
import SearchAddressDummyRepository
  from './infrastructure/repository/search-address-repository/search-address-dummy-repository';
import SearchAddressFetchRepository
  from './infrastructure/repository/search-address-repository/search-address-fetch-repository';
import UpdateAddressDummyRepository
  from './infrastructure/repository/update-address-repository/update-address-dummy-repository';
import UpdateAddressHttpRepository
  from './infrastructure/repository/update-address-repository/update-address-http-repository';
import CreateAddressDummyRepository
  from './infrastructure/repository/create-address-repository/create-address-dummy-repository';
import CreateAddressHttpRepository
  from './infrastructure/repository/create-address-repository/create-address-http-repository';
import VueStateManager from './infrastructure/state/vue-state-manager';
import { GetAddressQueryService } from './application/queries';
import UpdateAddressCommand from './application/commands/update-address-command';
import CreateAddressCommand from './application/commands/create-address-command';
import CreateOrUpdateAddress from './application/services/create-or-update-address';
import { SearchAddressRepository } from './domain/repository/search-address-repository';
import { UpdateAddressRepository } from './domain/repository/update-address-repository';
import { CreateAddressRepository } from './domain/repository/create-address-repository';
import { PhoneRepositoryStateManager as StateManager } from './domain/state/state-manager';

const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

export default function setupAddress(container: Container) {
  container
    .bind<SearchAddressRepository>(TYPES.ONBOARDING_SEARCH_ADDRESS_REPOSITORY)
    .to(
      withDummies
        ? SearchAddressDummyRepository
        : SearchAddressFetchRepository,
    )
    .inSingletonScope();

  container
    .bind<GetAddressQueryService>(TYPES.GET_ADDRESS_ONBOARDING_QUERY_SERVICE)
    .to(GetAddressQueryService)
    .inSingletonScope();

  container
    .bind<UpdateAddressRepository>(TYPES.ONBOARDING_UPDATE_ADDRESS_REPOSITORY)
    .to(
      withDummies
        ? UpdateAddressDummyRepository
        : UpdateAddressHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<UpdateAddressCommand>(TYPES.UPDATE_ADDRESS_COMMAND)
    .to(UpdateAddressCommand)
    .inSingletonScope();

  container
    .bind<StateManager>(TYPES.ONBOARDING_CATALOG_ADDRESS_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<CreateAddressRepository>(TYPES.CREATE_ADDRESS_REPOSITORY)
    .to(
      withDummies
        ? CreateAddressDummyRepository
        : CreateAddressHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<CreateAddressCommand>(TYPES.CREATE_ADDRESS_COMMAND)
    .to(CreateAddressCommand)
    .inSingletonScope();

  container
    .bind<CreateOrUpdateAddress>(TYPES.CREATE_OR_UPDATE_ADDRESS_SERVICE)
    .to(CreateOrUpdateAddress)
    .inSingletonScope();
}
