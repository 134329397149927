import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import { RelationshipRepository } from '../../../domain/repository';
import fixtures from './fixtures.json';

@Injectable()
export default class RelationshipDummyRepository implements RelationshipRepository {
  findAll() {
    return Promise.resolve(fixtures);
  }
}
