import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#202A52',
        secondary: '#4A4A4A',
        accent: '#0048D2',
        info: '#9B9B9B',
        error: '#b71c1c',
        lightgray: '#757575',
        darkgray: '#4a4a4a',
      },
    },
  },
  buttons: {
    capitalize: false,
  },
});
