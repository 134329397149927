/* eslint-disable max-len */

import TYPES from '../../../../../types';
import Inject from '@/modules/shared/domain/di/inject';
import CreateAddressCommand
  from '@/modules/onboarding/address/application/commands/create-address-command';
import SaveOnboardingSectionServiceBase
  from './save-onboarding-section-service-base';
import { SaveSectionResponseDto } from '../../../../domain/dtos/save-section-response-dto';
import { StoredPromiseDto } from '../../../../domain/dtos/stored-promise-dto';
import UpdatePersonCommand
  from '@/modules/onboarding/person/application/command/onboarding-update-person-command';
import OnboardingCreateCustomerAgreementCommand
  from '@/modules/onboarding/customer-agreement/application/commands/onboarding-create-customer-agreement-command';

export default class SaveTaxInformationSectionService extends SaveOnboardingSectionServiceBase {
  @Inject(TYPES.CREATE_ADDRESS_COMMAND)
  private readonly createAddressCommand!: CreateAddressCommand;

  @Inject(TYPES.ONBOARDING_UPDATE_PERSON_COMMAND)
  private readonly update_person_command!: UpdatePersonCommand;

  @Inject(TYPES.ONBOARDING_CREATE_CUSTOMER_AGREEMENT_COMMAND)
  private readonly on_boarding_create_customer_agreement_command!: OnboardingCreateCustomerAgreementCommand;

  async execute(): SaveSectionResponseDto {
    const tax_information_step = await this.get_on_boarding_step_query.execute('tax_information');

    const base_promises = [
      {
        id: 'create_address_command',
        command: this.createAddressCommand,
        data: {
          id: tax_information_step.payload.addresses[0].address.id as string,
          default: tax_information_step.payload.addresses[0].customer_address_default,
          country_id: tax_information_step.payload.addresses[0].address.country?.id as string,
          address_type: tax_information_step.payload.addresses[0].address.address_type,
          zip_code: tax_information_step.payload.addresses[0].address.zip_code,
          state: tax_information_step.payload.addresses[0].address.state,
          city: tax_information_step.payload.addresses[0].address.city,
          municipality: tax_information_step.payload.addresses[0].address.municipality,
          settlement: tax_information_step.payload.addresses[0].address.settlement,
          street: tax_information_step.payload.addresses[0].address.street,
          external_number: tax_information_step.payload.addresses[0].address.external_number,
          interior_number: tax_information_step.payload.addresses[0].address.interior_number,
          customer_address_id: tax_information_step.payload.addresses[0].customer_address_id,
          proof_of_address_id: tax_information_step.payload.addresses[0].customer_address_proof_of_address_id,
        },
      },
      {
        id: 'update_person_command',
        command: this.update_person_command,
        data: tax_information_step.payload.person,
      },
      {
        id: 'on_boarding_create_customer_agreement_command-0',
        command: this.on_boarding_create_customer_agreement_command,
        data: tax_information_step.payload.agreements[0],
      },
    ];

    const promises = (await this.filteredPromises(base_promises, 'tax_information')).map(
      (item) => item.command.execute(item.data),
    );

    return new Promise((resolve) => {
      Promise.allSettled(promises)
        .then((results) => {
          const rejected_promises: Array<StoredPromiseDto> = [];
          results.forEach((item, index) => {
            if (item.status === 'rejected') {
              rejected_promises.push({
                id: base_promises[index].id,
                error: item.reason.message.toLowerCase().split(' ').join('_'),
              });
            }
          });

          resolve({ tax_information: rejected_promises });
        });
    });
  }
}
