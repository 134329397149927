import { Container } from 'inversify-props';
import TYPES from './types';
import CreateCustomerAgreementDummyRepository
  from './infrastructure/repositories/create-customer-agreement-dummy-repository';
import CreateCustomerAgreementHttpRepository
  from './infrastructure/repositories/create-customer-agreement-http-repository';
import OnboardingCreateCustomerAgreementCommand
  from './application/commands/onboarding-create-customer-agreement-command';
import { CreateCustomerAgreementRepository }
  from './domain/repositories/create-customer-agreement-repository';

const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

export default function setupCustomerAgreement(container: Container) {
  container
    .bind<CreateCustomerAgreementRepository>(TYPES.ONBOARDING_CREATE_CUSTOMER_AGREEMENT_REPOSITORY)
    .to(
      withDummies
        ? CreateCustomerAgreementDummyRepository
        : CreateCustomerAgreementHttpRepository,
    ).inSingletonScope();

  container
    // eslint-disable-next-line max-len
    .bind<OnboardingCreateCustomerAgreementCommand>(TYPES.ONBOARDING_CREATE_CUSTOMER_AGREEMENT_COMMAND)
    .to(OnboardingCreateCustomerAgreementCommand)
    .inSingletonScope();
}
