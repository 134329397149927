import TYPES from '@/types';

// DOMAIN
import { CustomerDocumentStateManager } from '@/modules/onboarding/customer-document/domain/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class UpdateCustomerDocumentStateService {
  @Inject(TYPES.ONBOARDING_CUSTOMER_DOCUMENT_STATE_MANAGER)
  private readonly state_manager!: CustomerDocumentStateManager;

  update(document_id: string, base64: string) {
    this.state_manager.addBase64ToState({
      id: document_id,
      base64,
    });
  }
}
