import fixtures from './fixtures.json';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import { CountryRepository } from '../../../domain/repository';

@Injectable()
export default class CountryDummyRepository implements CountryRepository {
  findAll() {
    return Promise.resolve(fixtures);
  }
}
