import POLICIES from '../security/policies';
import UpdateProofOfAddressController
  from '@/vue-app/controllers/update-proof-of-address-controller';

export default {
  path: '/update-proof-of-address',
  component: () => import('@/vue-app/layouts/Layout.vue'),
  meta: {
    access_policy: POLICIES.UNAUTHENTICATED,
  },
  children: [
    {
      name: 'UpdateProofOfAddress',
      path: '/',
      beforeEnter: UpdateProofOfAddressController.beforeIndexEnter,
      component: () => import('@/vue-app/views/UpdateProofOfAddress.vue'),
    },
  ],
};
