import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import TYPES from '@/types';
import { OnboardingUpdateStepRepository } from '../../../domain/repositories';
import { StepEntity } from '../../../domain/entities/step-entity';

@Injectable()
export default class OnboardingUpdateStepHttpRepository implements OnboardingUpdateStepRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/on-boarding-steps/`;

  execute(step: StepEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.path.concat(`${step.id}/`), 'PATCH', {
        current_step: step.current_step,
        payload: step.payload,
      })
        .then(() => {
          resolve();
        })
        .catch(reject);
    });
  }
}
