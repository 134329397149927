import { Container } from 'inversify-props';
import { VerifyNipRepository } from './domain/repository';
import { VerifyNipHttpRepository, VerifyNipDummyRepository } from './infrastructure/repository';
import { VerifyNipCommandService } from './application/commands';
import VERIFY_NIP_TYPES from './types';

export default function setupVerifyNip(container: Container) {
  container
    .bind<VerifyNipRepository>(VERIFY_NIP_TYPES.VERIFY_NIP_REPOSITORY)
    .to(
      process.env.VUE_APP_REPOSITORY_MODE === 'dummy'
        ? VerifyNipDummyRepository
        : VerifyNipHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<VerifyNipCommandService>(VERIFY_NIP_TYPES.VERIFY_NIP_COMMAND_SERVICE)
    .to(VerifyNipCommandService)
    .inSingletonScope();
}
