import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import { VerifyNipRepository } from '../../domain/repository/verify-nip-repository';

@Injectable()
export default class VerifyNipDummyRepository implements VerifyNipRepository {
  create(): Promise<any> {
    return Promise.resolve({
      success: true,
      message: 'All Ok',
      errors: '',
    });
  }
}
