// eslint-disable-next-line
import { v4 as uuidv4 } from 'uuid';

import Injectable from '../../domain/dependency_injection/injectable';
import Functions from '../../domain/utils/functions';

@Injectable()
export default class UtilFunctions extends Functions {
  get_uuidv4(): string {
    return uuidv4();
  }

  convert_file_to_base_64(input_file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(input_file);
    });
  }
}
