import Vue from 'vue';
import Vuex from 'vuex';
import app from './app';
import layout from './layout';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    layout,
  },
});
