import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import {
  IdentityVerificationServiceHttpRepository,
  IdentityVerificationModuleDummyRepository,
} from './infrastructure/repositories';
import IdentityVerificationStatusSubscriptionVueStateManager
  from './infrastructure/state-manager/vue-state-manager';

// Application
import {
  GetIdentityVerificationServiceQuery,
  GetIdentityVerificationStatusQuery,
} from './application/queries';
import { IdentityVerificationStatusSubscription } from './application/subscriptions';
import ResetIdentityVerificationStateManager
  from './application/services/reset-identity-verification-state-manager';

// Domain
import { IdentityVerificationServiceRepository } from './domain/repositories';
import { StateManager } from './domain/state-manager';

// eslint-disable-next-line import/prefer-default-export
export function setupIdentityVerificationModule(container: Container) {
  container
    .bind<GetIdentityVerificationServiceQuery>(TYPES.GET_IDENTITY_VERIFICATION_SERVICE_QUERY)
    .to(GetIdentityVerificationServiceQuery)
    .inSingletonScope();

  container
    .bind<GetIdentityVerificationStatusQuery>(TYPES.GET_IDENTITY_VERIFICATION_STATUS_QUERY)
    .to(GetIdentityVerificationStatusQuery)
    .inSingletonScope();

  container
    .bind<IdentityVerificationServiceRepository>(TYPES.IDENTITY_VERIFICATION_SERVICE_REPOSITORY)
    .to(
      process.env.VUE_APP_REPOSITORY_MODE === 'dummy'
        ? IdentityVerificationModuleDummyRepository
        : IdentityVerificationServiceHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<IdentityVerificationStatusSubscription>(TYPES.IDENTITY_VERIFICATION_STATUS_SUBSCRIPTION)
    .to(IdentityVerificationStatusSubscription)
    .inSingletonScope();

  container
    // eslint-disable-next-line max-len
    .bind<StateManager>(TYPES.IDENTITY_VERIFICATION_STATUS_SUBSCRIPTION_STATE_MANAGER)
    .to(IdentityVerificationStatusSubscriptionVueStateManager)
    .inSingletonScope();

  container
    .bind<ResetIdentityVerificationStateManager>(TYPES.IDENTITY_VERIFICATION_RESET_STATE_MANAGER)
    .to(ResetIdentityVerificationStateManager)
    .inSingletonScope();
}
