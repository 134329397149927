/* eslint-disable @typescript-eslint/ban-ts-ignore */

import Injectable from '@/modules/shared/domain/di/injectable';
import Service from '@/modules/shared/domain/services/service';
import { StepDto } from '../../../domain/dtos/step-dto';
import STEPS from './steps.json';

@Injectable()
export default class GetOnboardingStepName extends Service<string, StepDto> {
  readonly = true;

  internalExecute(step: StepDto) {
    if (step.step > 6) {
      if (step.substep > 1) {
        // @ts-ignore
        return STEPS[step.step][step.substep][step.internal_step];
      }
    }

    // @ts-ignore
    return STEPS[step.step][step.substep];
  }
}
