import Injectable from '../../domain/di/injectable';
import { Geolocation } from '../../domain/geolocation';
import { CoordinatesDto } from '../../domain/dtos/coordinates-dto';

@Injectable()
export default class NavigatorGeolocation implements Geolocation {
  watch_id: null | number = null;

  getCurrentPosition(options = {}): Promise<CoordinatesDto> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(({ coords }) => resolve(coords), reject, options);
    });
  }

  watchPosition(options = {}) {
    if (!this.watch_id) {
      this.watch_id = navigator.geolocation.watchPosition(({ coords }) => {
        const event = new CustomEvent('geolocation', { detail: coords });

        window.dispatchEvent(event);
      }, (error) => {
        throw error;
      }, options);
    }
  }

  stopWatchPosition() {
    if (this.watch_id) {
      navigator.geolocation.clearWatch(this.watch_id);
    }
  }
}
