import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import TYPES from '@/types';
import { UpdateAddressRepository } from '../../../domain/repository/update-address-repository';
import { AddressEntity } from '../../../domain/entities/address-entity';

@Injectable()
export default class UpdateAddressHttpRepository implements UpdateAddressRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/address/`;

  update(address: AddressEntity): Promise<void> {
    const address_patch_data = { ...address };
    // eslint-disable-next-line prefer-destructuring
    address_patch_data.address_type = address_patch_data.address_type.split('.')[1];
    delete address_patch_data.id;
    delete address_patch_data.country;
    delete address_patch_data.created_at;
    delete address_patch_data.updated_at;
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.path.concat(`${address.id}/`), 'PATCH', address_patch_data)
        .then(() => {
          resolve();
        })
        .catch(reject);
    });
  }
}
