import TYPES from '@/modules/shared/types';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import { SourceOfResourcesRepository } from '../../domain/repository';
import { SourceOfResourceEntity } from '../../domain/entities/source-of-resource-entity';

@Injectable()
export default class SourceOfResourcesFetchRepository implements SourceOfResourcesRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/catalogs/resources_origin/`;

  findAll(): Promise<Array<SourceOfResourceEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint)
        .then((response) => {
          response.json()
            .then(({ data, success, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
