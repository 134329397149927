import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import TYPES from '@/types';
import { CreateAddressRepository } from '../../../domain/repository/create-address-repository';
import { CreateAddressDto } from '../../../domain/dtos/create-address-dto';

@Injectable()
export default class CreateAddressHttpRepository implements CreateAddressRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/address/`;

  execute(address: CreateAddressDto): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.path, 'POST', address)
        .then((response) => {
          response.json().then(({ message, success }) => {
            if (success) {
              resolve();
            } else {
              reject(new Error(message));
            }
          });
        })
        .catch(reject);
    });
  }
}
