import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Query from '@/modules/shared/domain/services/query';
import TYPES from '@/modules/onboarding/phone/types';
import { SearchCellPhonesRepository } from '../../domain/repository/search-cell-phones-repository';
import { CustomerCellphoneEntity } from '../../domain/entities/customer-cellphone-entity';
import { PhoneRepositoryStateManager as StateManager } from '../../domain/state/state-manager';

@Injectable()
export default class GetCellPhonesQuery extends Query<Array<CustomerCellphoneEntity>> {
  @Inject(TYPES.ONBOARDING_PHONE_REPOSITORY_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.SEARCH_CELL_PHONES_REPOSITORY)
  private readonly repository!: SearchCellPhonesRepository;

  internalExecute() {
    if (!this.state_manager.state.loaded_cell_phones) {
      this.repository.findAll().then((cell_phones) => {
        this.state_manager.patch({ cell_phones });
      });
    }

    return this.state_manager.state.cell_phones;
  }
}
