import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Query from '@/modules/shared/domain/services/query';
import TYPES from '@/modules/onboarding/phone/types';
import { SearchPhonesRepository } from '../../domain/repository/search-phones-repository';
import { CustomerPhoneEntity } from '../../domain/entities/customer-phone-entity';
import { PhoneRepositoryStateManager as StateManager } from '../../domain/state/state-manager';

@Injectable()
export default class GetPhonesQueryService extends Query<Array<CustomerPhoneEntity>> {
  @Inject(TYPES.ONBOARDING_PHONE_REPOSITORY_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.SEARCH_PHONES_REPOSITORY)
  private readonly repository!: SearchPhonesRepository;

  internalExecute() {
    if (!this.state_manager.state.loaded_phones) {
      this.repository.findAll().then((phones) => {
        this.state_manager.patch({ phones });
      });
    }

    return this.state_manager.state.phones;
  }
}
