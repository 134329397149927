/* eslint-disable max-len */

import TYPES from '../../../../../types';
import Inject from '@/modules/shared/domain/di/inject';
import CreateCustomerGeolocationCommand
  from '@/modules/onboarding/customer-geolocations/application/commands/create-customer-geolocation-command';
import SaveOnboardingSectionServiceBase
  from './save-onboarding-section-service-base';
import { SaveSectionResponseDto } from '../../../../domain/dtos/save-section-response-dto';
import { StoredPromiseDto } from '../../../../domain/dtos/stored-promise-dto';

export default class SaveVerifyNipSectionService extends SaveOnboardingSectionServiceBase {
  @Inject(TYPES.CREATE_CUSTOMER_GEOLOCATION_COMMAND)
  private readonly createCustomerGeolocationCommand!: CreateCustomerGeolocationCommand;

  async execute(): SaveSectionResponseDto {
    const verify_nip_step = await this.get_on_boarding_step_query.execute('verify_nip');

    const base_promises = [
      {
        id: 'create_customer_geolocation_command',
        command: this.createCustomerGeolocationCommand,
        data: {
          id: verify_nip_step.payload.geolocation.id,
          customer_id: sessionStorage.getItem('user_id'),
          reference_id: verify_nip_step.payload.geolocation.reference.id,
          latitude: verify_nip_step.payload.geolocation.coordinates.latitude,
          longitude: verify_nip_step.payload.geolocation.coordinates.longitude,
        },
      },
    ];

    const promises = (await this.filteredPromises(base_promises, 'verify_nip')).map(
      (item) => item.command.execute(item.data),
    );

    return new Promise((resolve) => {
      Promise.allSettled(promises)
        .then((results) => {
          const rejected_promises: Array<StoredPromiseDto> = [];
          results.forEach((item, index) => {
            if (item.status === 'rejected') {
              rejected_promises.push({
                id: base_promises[index].id,
                error: item.reason.message.toLowerCase().split(' ').join('_'),
              });
            }
          });

          resolve({ verify_nip: rejected_promises });
        });
    });
  }
}
