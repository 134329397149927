import TYPES from '../../types';

// Domain
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class GeolocationService {
  @Inject(TYPES.GEOLOCATION_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  setGeolocationStatus(geolocation_status: boolean): void {
    this.state_manager.state.is_geolocation_active = geolocation_status;
  }

  getGeolocationStatus(): boolean {
    return this.state_manager.state.is_geolocation_active;
  }
}
