/* eslint-disable max-len */

import TYPES from '../../../../../types';
import Inject from '@/modules/shared/domain/di/inject';
import OnboardingCreateCustomerAgreementCommand
  from '@/modules/onboarding/customer-agreement/application/commands/onboarding-create-customer-agreement-command';
import CreateExpectedTransactionalityCommand
  from '@/modules/onboarding/expected-transactionality/application/commands/create-expected-transactionality-command';
import SaveOnboardingSectionServiceBase
  from './save-onboarding-section-service-base';
import { SaveSectionResponseDto } from '../../../../domain/dtos/save-section-response-dto';
import { StoredPromiseDto } from '../../../../domain/dtos/stored-promise-dto';

class SaveFinancialInformationSectionService extends SaveOnboardingSectionServiceBase {
  @Inject(TYPES.ONBOARDING_CREATE_CUSTOMER_AGREEMENT_COMMAND)
  private readonly onboardingCreateCustomerAgreementCommand!: OnboardingCreateCustomerAgreementCommand;

  @Inject(TYPES.CREATE_EXPECTED_TRANSACTIONALITY_COMMAND)
  private readonly createExpectedTransactionaliyCommand!: CreateExpectedTransactionalityCommand;

  async execute(): SaveSectionResponseDto {
    const financial_step = await this.get_on_boarding_step_query.execute('financial_information');

    const base_promises = [
      {
        id: 'onboarding_create_customer_agreement',
        command: this.onboardingCreateCustomerAgreementCommand,
        data: financial_step.payload.agreements[0],
      },
      {
        id: 'create_expected_transactionality_command',
        command: this.createExpectedTransactionaliyCommand,
        data: {
          id: financial_step.payload.financial_information.expected_transactionality_id,
          estimated_monthly_deposits: financial_step.payload.financial_information.estimated_monthly_deposits,
          estimated_monthly_withdrawals: financial_step.payload.financial_information.estimated_monthly_withdrawals,
          estimated_monthly_amount_deposits: financial_step.payload.financial_information.estimated_monthly_amount_deposits,
          estimated_monthly_amount_withdrawals: financial_step.payload.financial_information.estimated_monthly_amount_withdrawals,
          initial_investment: financial_step.payload.financial_information.initial_investment,
          maximum_expected_investment: financial_step.payload.financial_information.maximum_expected_investment,
        },
      },
    ];

    const promises = (await this.filteredPromises(base_promises, 'financial_information')).map(
      (item) => item.command.execute(item.data),
    );

    return new Promise((resolve) => {
      Promise.allSettled(promises)
        .then((results) => {
          const rejected_promises: Array<StoredPromiseDto> = [];
          results.forEach((item, index) => {
            if (item.status === 'rejected') {
              rejected_promises.push({
                id: base_promises[index].id,
                error: item.reason.message.toLowerCase().split(' ').join('_'),
              });
            }
          });

          resolve({ financial_information: rejected_promises });
        });
    });
  }
}

export default SaveFinancialInformationSectionService;
