import Injectable from '@/modules/shared/domain/di/injectable';
import { SearchDocumentsRepository } from '../../../../domain/repository/search-documents-repository';
import { DocumentEntity } from '../../../../domain/entities/document-entity';
import fixtures from './fixtures.json';

@Injectable()
export default class SearchDocumentsDummyRepository implements SearchDocumentsRepository {
  execute(): Promise<Array<DocumentEntity>> {
    return Promise.resolve(fixtures);
  }
}
