import TYPES from '@/types';

// DOMAIN
import { MaritalStatusRepository } from '@/modules/onboarding/catalogs/marital-status/domain/repositories/marital-status-repository';
import { MaritalStatusEntity } from '@/modules/onboarding/catalogs/marital-status/domain/entities/marital-status-entity';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import { Response } from '@/modules/shared/domain/responses/response';
import Inject from '@/modules/shared/domain/di/inject';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class MaritalStatusHttpRepository implements MaritalStatusRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/catalogs/marital_status/`;

  getMaritalStatusItems(): Promise<Array<MaritalStatusEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint)
        .then<Response>((response) => response.json())
        .then(({ success, data, message }) => {
          if (success) {
            resolve(data);
          } else {
            reject(new Error(message));
          }
        })
        .catch(reject);
    });
  }
}
