/* eslint-disable max-len */

import Injectable from '@modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';
import State from '../../domain/state-manager/state';
import { StateManager } from '../../domain/state-manager';
import TYPES from '../../types';

@Injectable()
export default class GetIdentityVerificationStatusQuery extends Query<State> {
  @Inject(TYPES.IDENTITY_VERIFICATION_STATUS_SUBSCRIPTION_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  internalExecute(): State {
    return this.state_manager.state;
  }
}
