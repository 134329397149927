import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Query from '@/modules/shared/domain/services/query';
import TYPES from '../../types';
import { StateManager } from '../../domain/state/state-manager';
import { NationalityRepository } from '../../domain/repository/nationality-repository';
import { NationalityEntity } from '../../domain/entities/nationality-entity';

@Injectable()
export default class GetNationalitiesQueryService extends Query<Promise<Array<NationalityEntity>>> {
  @Inject(TYPES.ONBOARDING_CATALOG_NATIONALITY_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.NATIONALITY_REPOSITORY)
  private readonly repository!: NationalityRepository;

  internalExecute(): Promise<Array<NationalityEntity>> {
    return new Promise((resolve, reject) => {
      const state_manager_loaded = this.state_manager.state.loaded;

      if (!state_manager_loaded) {
        this.repository
          .findAll()
          .then((items) => {
            this.state_manager.patch({ items });
            resolve(items);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.items);
      }
    });
  }
}
