import { container } from 'inversify-props';
import TYPES from '@/types/index';
// eslint-disable-next-line import/no-cycle
import Runner from '../runner/runner';

export default abstract class Service<Result = any, Param = any> {
  abstract readonly: boolean

  abstract internalExecute(param?: Param): Result

  execute(param?: Param): Result {
    return container.get<Runner>(TYPES.RUNNER).run(this, param) as Result;
  }
}
