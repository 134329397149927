export default class AppViewModel {
  readonly zendesk_script = {
    src: 'https://static.zdassets.com/ekr/snippet.js?key=11c6a569-c189-42a2-a1cf-ed1f208d3270',
    id: 'ze-snippet',
  };

  initialize() {
    this.appendZendeskScript();
  }

  appendZendeskScript() {
    const zendesk_script = document.createElement('script');
    zendesk_script.setAttribute('id', this.zendesk_script.id);
    zendesk_script.setAttribute('src', this.zendesk_script.src);
    document.head.appendChild(zendesk_script);
  }
}
