import { Container } from 'inversify-props';
import TYPES from '@/modules/onboarding/on-boarding/types';
import CreateOnBoardingDummyRepository
  from './infrastructure/repository/create-on-boarding-repository/create-on-boarding-dummy-repository';
import CreateOnBoardingHttpRepository
  from './infrastructure/repository/create-on-boarding-repository/create-on-boarding-http-repository';
import CreateOnBoardingCommand from './application/commands/create-on-boarding-command';
import { CreateOnBoardingRepository } from './domain/repository/create-on-boarding-repository';

const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

export default function setupAddress(container: Container) {
  container
    .bind<CreateOnBoardingRepository>(TYPES.CREATE_ON_BOARDING_REPOSITORY)
    .to(
      withDummies
        ? CreateOnBoardingDummyRepository
        : CreateOnBoardingHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<CreateOnBoardingCommand>(TYPES.CREATE_ON_BOARDING_COMMAND)
    .to(CreateOnBoardingCommand)
    .inSingletonScope();
}
