import TYPES from '@/modules/shared/types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import { CreateBankAccountNumberRepository }
  from '../../../domain/repositories/create-bank-account-number-repository';
import { BankAccountNumberEntity } from '../../../domain/entities/bank-account-number-entity';

@Injectable()
class CreateBankAccountNumberHttpRepository implements CreateBankAccountNumberRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/bank-account-number/`;

  create(bank_account_number: BankAccountNumberEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint, 'POST', bank_account_number)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}

export default CreateBankAccountNumberHttpRepository;
