import Vue from 'vue';

// DOMAIN
import { Base64FileDto } from '@/modules/onboarding/customer-document/domain/dtos';
import {
  CustomerDocumentState,
  CustomerDocumentStateManager,
} from '@/modules/onboarding/customer-document/domain/state-manager';
import Observer from '@/modules/shared/domain/observer/observer';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomerDocumentVueStateManager implements CustomerDocumentStateManager {
  private _state: CustomerDocumentState = Vue.observable<CustomerDocumentState>(
    new CustomerDocumentState(),
  );

  private readonly observers: Observer[] = [];

  get state(): CustomerDocumentState {
    return this._state;
  }

  set state(value: CustomerDocumentState) {
    this._state = value;
    this.notifyAll();
  }

  addBase64ToState(base64_file: Base64FileDto) {
    this.state.current_customer_documents.push(base64_file);
  }

  notifyAll() {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer) {
    this.observers.push(observer);
  }
}
