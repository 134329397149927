import { Container } from 'inversify-props';
import TYPES from './types';
import SearchPersonDummyRepository
  from './infrastructure/repository/search-person-repository/search-person-dummy-repository';
import SearchPersonHttpRepository
  from './infrastructure/repository/search-person-repository/search-person-http-repository';
import UpdatePersonDummyRepository
  from './infrastructure/repository/update-person-repository/update-person-dummy-repository';
import UpdatePersonHttpRepository
  from './infrastructure/repository/update-person-repository/update-person-http-repository';
import VueStateManager from './infrastructure/state/vue-state-manager';
import { GetPersonQueryService } from './application/queries';
import UpdatePersonCommand from './application/command/onboarding-update-person-command';
import { SearchPersonRepository } from './domain/repository/search-person-repository';
import { UpdatePersonRepository } from './domain/repository/update-person-repository';
import { StateManager } from './domain/state/state-manager';

const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

export default function setupPerson(container: Container) {
  container
    .bind<StateManager>(TYPES.ONBOARDING_PERSON_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<SearchPersonRepository>(TYPES.SEARCH_PERSON_REPOSITORY)
    .to(
      withDummies
        ? SearchPersonDummyRepository
        : SearchPersonHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetPersonQueryService>(TYPES.GET_PERSON_QUERY_SERVICE)
    .to(GetPersonQueryService)
    .inSingletonScope();

  container
    .bind<UpdatePersonRepository>(TYPES.ONBOARDING_UPDATE_PERSON_REPOSITORY)
    .to(
      withDummies
        ? UpdatePersonDummyRepository
        : UpdatePersonHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<UpdatePersonCommand>(TYPES.ONBOARDING_UPDATE_PERSON_COMMAND)
    .to(UpdatePersonCommand)
    .inSingletonScope();
}
