import TYPES from '@/types';

// DOMAIN
import { PromotorEntity } from '@/modules/onboarding/catalogs/promotor/domain/entities/promotor-entity';
import { PromotorRepository } from '@/modules/onboarding/catalogs/promotor/domain/repositories/promotor-repository';
import { PromotorStateManager } from '@/modules/onboarding/catalogs/promotor/domain/state/promotor-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchPromotorQuery extends Query<Promise<Array<PromotorEntity>>, string> {
  @Inject(TYPES.PROMOTOR_REPOSITORY)
  readonly repository!: PromotorRepository;

  @Inject(TYPES.PROMOTOR_STATE_MANAGER)
  readonly state_manager!: PromotorStateManager;

  internalExecute(search_term: string): Promise<Array<PromotorEntity>> {
    return new Promise((resolve, reject) => {
      const state_manager_items_loaded = this.state_manager.state.loaded;
      const different_search_term = this.state_manager.state.search_term !== search_term;

      if (!state_manager_items_loaded || different_search_term) {
        this.repository
          .searchPromotor(search_term)
          .then((items) => {
            this.state_manager.patch({ items, search_term });
            resolve(items);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.items);
      }
    });
  }
}
