export default class State {
  mati_flow_id = '';

  success = true;

  name = '';

  step = 0;

  percent = 0;

  user_id = '';

  description = '';

  message = '';

  has_problem = false;

  status = '';
}
