import TYPES from '@/modules/shared/types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import { OnboardingSearchStepsRepository } from '../../../domain/repositories/onboarding-search-steps-repository';
import { StepEntity } from '../../../domain/entities/step-entity';

@Injectable()
class OnboardingSearchStepsHttpRepository implements OnboardingSearchStepsRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/on-boarding-steps/`;

  execute(): Promise<Array<StepEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}

export default OnboardingSearchStepsHttpRepository;
