import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import EmailDummyRepository from './infrastructure/repositories/email-dummy-repository';
import EmailHttpRepository from './infrastructure/repositories/email-http-repository';

// Application
import OnBoardingVerifyEmailExistenceQuery
  from './application/queries/on-boarding-verify-email-existence-query';

// Domain
import { EmailRepository } from './domain/repositories/email-repository';

export default function setupEmail(container: Container) {
  const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<EmailRepository>(TYPES.ON_BOARDING_EMAIL_REPOSITORY)
    .to(
      with_dummies
        ? EmailDummyRepository
        : EmailHttpRepository,
    ).inSingletonScope();

  container
    .bind<OnBoardingVerifyEmailExistenceQuery>(TYPES.ON_BOARDING_VERIFY_EMAIL_EXISTENCE_QUERY)
    .to(OnBoardingVerifyEmailExistenceQuery)
    .inSingletonScope();
}
