// DOMAIN
import { ProofOfAddressRepository } from '@/modules/onboarding/proof-of-address/domain/repositories/proof-of-address-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class ProofOfAddressDummyRepository implements ProofOfAddressRepository {
  useIneAsProofOfAddress(): Promise<void> {
    return Promise.resolve();
  }
}
