import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import { CreateAddressDto } from '../../domain/dtos/create-address-dto';
import { CustomerAddressEntity } from '../../domain/entities/customer-address-entity';
import { CreateAddressRepository } from '../../domain/repository/create-address-repository';
import { PhoneRepositoryStateManager as StateManager } from '../../domain/state/state-manager';

@Injectable()
export default class CreateAddressCommand extends Command<CreateAddressDto> {
  @Inject(TYPES.CREATE_ADDRESS_REPOSITORY)
  private readonly repository!: CreateAddressRepository;

  @Inject(TYPES.ONBOARDING_CATALOG_ADDRESS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  internalExecute(address: CreateAddressDto) {
    return new Promise((resolve, reject) => {
      this.repository.execute(address)
        .then(() => {
          const newAddress: CustomerAddressEntity = {
            customer_address_id: address.customer_address_id,
            customer_address_proof_of_address_id: address.proof_of_address_id,
            customer_address_default: address.default,
            address: {
              ...address,
              country: {
                id: address.country_id,
                name: 'México',
              },
              created_at: new Date().toUTCString(),
              updated_at: new Date().toUTCString(),
            },
          };
          this.state_manager.patch({ items: [...this.state_manager.state.items, newAddress] });
          resolve();
        })
        .catch(reject);
    });
  }
}
