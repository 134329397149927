import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Command from '@/modules/shared/domain/services/command';
import USER_TYPES from '@/modules/register/user/types';
import { UserRepository } from '@/modules/register/user/domain/repository/user-repository';
import { UserDto } from '@/modules/register/user/domain/dtos/user-dto';

@Injectable()
export default class RegisterUserCommandService extends Command {
  @Inject(USER_TYPES.USER_REPOSITORY)
    private readonly user_repository!: UserRepository

  internalExecute(user: UserDto): Promise<void> {
    return this.user_repository.create(user);
  }
}
