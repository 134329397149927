import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Query from '@/modules/shared/domain/services/query';
import { SearchAddressRepository } from '../../domain/repository/search-address-repository';
import { CustomerAddressEntity } from '../../domain/entities/customer-address-entity';
import { PhoneRepositoryStateManager as StateManager } from '../../domain/state/state-manager';

@Injectable()
export default class GetAddressQueryService extends Query<Array<CustomerAddressEntity>, boolean> {
  @Inject(TYPES.ONBOARDING_SEARCH_ADDRESS_REPOSITORY)
  private readonly repository!: SearchAddressRepository;

  @Inject(TYPES.ONBOARDING_CATALOG_ADDRESS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  internalExecute(refetch?: boolean) {
    if (!this.state_manager.state.loaded || refetch) {
      this.repository.get()
        .then((items) => {
          this.state_manager.patch({ items });
        });
    }

    return this.state_manager.state.items;
  }
}
