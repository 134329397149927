import TYPES from '../../types';
import SHARED_TYPES from '@/modules/shared/types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Subscription from '@/modules/shared/domain/use-cases/subscription';
import { Geolocation } from '@/modules/shared/domain/geolocation';
import { StateManager } from '../../domain/state/state-manager';

@Injectable()
class OnCurrentPositionSubscription extends Subscription {
  @Inject(SHARED_TYPES.GEOLOCATION)
  private readonly geolocation!: Geolocation;

  @Inject(TYPES.GEOLOCATION_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  internalExecute() {
    this.geolocation.watchPosition();
    window.addEventListener('geolocation', (event) => {
      this.state_manager.patch({
        current_position: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          latitude: event.detail.latitude,
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          longitude: event.detail.longitude,
        },
      });
    });
  }

  close() {
    this.geolocation.stopWatchPosition();
  }
}

export default OnCurrentPositionSubscription;
