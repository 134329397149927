import TYPES from '../../types';

// Domain
import State from '../../domain/state-manager/state';
import { StateManager } from '../../domain/state-manager';
import Inject from '@modules/shared/domain/di/inject';

export default class UpdateStateManagerAndGetUseful {
  @Inject(TYPES.IDENTITY_VERIFICATION_STATUS_SUBSCRIPTION_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  execute(new_state: State): boolean {
    const flow_ids: Array<string> = sessionStorage.getItem('mati_flow_ids')
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ? JSON.parse(sessionStorage.getItem('mati_flow_ids')!)
      : [];

    if (
      flow_ids[flow_ids.length - 1] === new_state.mati_flow_id
      || !flow_ids.includes(new_state.mati_flow_id)
    ) {
      if (!flow_ids.includes(new_state.mati_flow_id)) {
        flow_ids.push(new_state.mati_flow_id);
        sessionStorage.setItem('mati_flow_ids', JSON.stringify(flow_ids));
      }

      this.state_manager.patch(new_state);

      return true;
    }

    return false;
  }
}
