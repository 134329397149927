import TYPES from '../../../../../types';
import Inject from '@/modules/shared/domain/di/inject';

// Application
import OnboardingCreateCustomerAgreementCommand
  from '@/modules/onboarding/customer-agreement/application/commands/onboarding-create-customer-agreement-command';
import CreateBankAccountNumberCommand
  from '@/modules/onboarding/bank-account-number/application/commands/create-bank-account-number-command';
import SaveOnboardingSectionServiceBase
  from './save-onboarding-section-service-base';

// Domain
import { StoredPromiseDto } from '../../../../domain/dtos/stored-promise-dto';
import { SaveSectionResponseDto } from '../../../../domain/dtos/save-section-response-dto';

export default class SaveBankInformationService extends SaveOnboardingSectionServiceBase {
  @Inject(TYPES.ONBOARDING_CREATE_CUSTOMER_AGREEMENT_COMMAND)
  // eslint-disable-next-line max-len
  private readonly onboardingCreateCustomerAgreementCommand!: OnboardingCreateCustomerAgreementCommand;

  @Inject(TYPES.ONBOARDING_CREATE_BANK_ACCOUNT_NUMBER_COMMAND)
  private readonly onboardingCreateBankAccountNumberCommand!: CreateBankAccountNumberCommand;

  async execute(): SaveSectionResponseDto {
    const bank_information_step = await this.get_on_boarding_step_query.execute('bank_information');

    const base_promises = [
      {
        id: 'onboarding_create_customer_agreement_command-0',
        command: this.onboardingCreateCustomerAgreementCommand,
        data: bank_information_step.payload.agreements[0],
      },
      {
        id: 'onboarding_create_customer_agreement_command-1',
        command: this.onboardingCreateCustomerAgreementCommand,
        data: bank_information_step.payload.agreements[1],
      },
      {
        id: 'onboarding_create_bank_account_number_command',
        command: this.onboardingCreateBankAccountNumberCommand,
        data: {
          number: bank_information_step.payload.bank_account,
          bank_name: bank_information_step.payload.financial_institution,
          bank_id: bank_information_step.payload.bank_id,
        },
      },
    ];

    const promises = (await this.filteredPromises(base_promises, 'bank_information')).map(
      (item) => item.command.execute(item.data),
    );

    return new Promise((resolve) => {
      Promise.allSettled(promises)
        .then((results) => {
          const rejected_promises: Array<StoredPromiseDto> = [];

          results.forEach((item, index) => {
            if (item.status === 'rejected') {
              rejected_promises.push({
                id: base_promises[index].id,
                error: item.reason.message.toLowerCase().split(' ').join('_'),
              });
            }
          });

          resolve({ bank_information: rejected_promises });
        });
    });
  }
}
