import { detect } from 'detect-browser';
import { BrowserModulesType } from '../types/browser-modules-type';

export function setupAdapterAccordingToBrowser(modules: BrowserModulesType) {
  const browser = detect();

  switch (browser?.name) {
    case 'chrome':
      return modules.chrome || modules.default;
    case 'edge-chromium':
      return modules.edge_chromium || modules.default;
    default:
      return modules.default;
  }
}

export function setupFrameBuster() {
  let externallyFramed = false;

  try {
    externallyFramed = window.top.location.host !== window.location.host;
  } catch {
    window.top.location = window.location;
  }

  if (externallyFramed) {
    window.top.location = window.location;
  }
}
