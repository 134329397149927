import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';
import { StateManager } from '../../domain/state/state-manager';

@Injectable()
export default class GetInternetStatusQuery extends Query<boolean> {
  @Inject(TYPES.INTERNET_STATUS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  internalExecute(): boolean {
    return this.state_manager.state.online;
  }
}
