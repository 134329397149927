import Vue from 'vue';

// DOMAIN
import { MaritalStatusStateManager } from '@/modules/onboarding/catalogs/marital-status/domain/state/marital-status-state-manager';
import { MaritalStatusState } from '@/modules/onboarding/catalogs/marital-status/domain/state/marital-status-state';
import Observer from '@/modules/shared/domain/observer/observer';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class MaritalStatusVueStateManager implements MaritalStatusStateManager {
  private _state: MaritalStatusState = Vue.observable<MaritalStatusState>(new MaritalStatusState());

  private readonly observers: Observer[] = [];

  get state(): MaritalStatusState {
    return this._state;
  }

  set state(value: MaritalStatusState) {
    this._state = value;
    this.notifyAll();
  }

  patch(new_state: Partial<MaritalStatusState>): void {
    if (new_state.items) {
      this.state.loaded = true;
      this.state.items = new_state.items;
    }
  }

  notifyAll(): void {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer): void {
    this.observers.push(observer);
  }
}
