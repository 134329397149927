import TYPES from '../../types';
import { UserEntity } from '../../domain/entities/user-entity';
import { CurrentUserRepository } from '../../domain/repositories/current-user-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetCurrentUserQuery extends Query<Promise<UserEntity>> {
  @Inject(TYPES.CURRENT_USER_REPOSITORY)
  private readonly current_user_repository!: CurrentUserRepository;

  internalExecute(): Promise<UserEntity> {
    return this.current_user_repository.get();
  }
}
