import TYPES from '../../../../../types';
import Inject from '@/modules/shared/domain/di/inject';
import OnboardingCreateCustomerAgreementCommand
  from '@/modules/onboarding/customer-agreement/application/commands/onboarding-create-customer-agreement-command';
import SaveOnboardingSectionServiceBase
  from './save-onboarding-section-service-base';
import { SaveSectionResponseDto } from '../../../../domain/dtos/save-section-response-dto';
import { StoredPromiseDto } from '../../../../domain/dtos/stored-promise-dto';

export default class SaveConfirmationSectionService extends SaveOnboardingSectionServiceBase {
  @Inject(TYPES.ONBOARDING_CREATE_CUSTOMER_AGREEMENT_COMMAND)
  // eslint-disable-next-line max-len
  private readonly onboardingCreateCustomerAgreementCommand!: OnboardingCreateCustomerAgreementCommand;

  async execute(): SaveSectionResponseDto {
    const confirmation_step = await this.get_on_boarding_step_query.execute('confirmation');

    const base_promises = [
      {
        id: 'onboarding_create_customer_agreement_command-0',
        command: this.onboardingCreateCustomerAgreementCommand,
        data: confirmation_step.payload.agreements[0],
      },
      {
        id: 'onboarding_create_customer_agreement_command-1',
        command: this.onboardingCreateCustomerAgreementCommand,
        data: confirmation_step.payload.agreements[1],
      },
    ];

    const promises = (await this.filteredPromises(base_promises, 'confirmation')).map(
      (item) => item.command.execute(item.data),
    );

    return new Promise((resolve) => {
      Promise.allSettled(promises)
        .then((results) => {
          const rejected_promises: Array<StoredPromiseDto> = [];
          results.forEach((item, index) => {
            if (item.status === 'rejected') {
              rejected_promises.push({
                id: base_promises[index].id,
                error: item.reason.message.toLowerCase().split(' ').join('_'),
              });
            }
          });

          resolve({ confirmation: rejected_promises });
        });
    });
  }
}
