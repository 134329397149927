import { Container } from 'inversify-props';
import TYPES from '@/modules/onboarding/expected-transactionality/types';
import CreateExpectedTransactionalityDummyRepository
  from './infrastructure/repository/create-expected-transactionality-repository/create-expected-transactionality-dummy-repository';
import CreateExpectedTransactionalityHttpRepository
  from './infrastructure/repository/create-expected-transactionality-repository/create-expected-transactionality-http-repository';
import CreateExpectedTransactionalityCommand from './application/commands/create-expected-transactionality-command';
import { CreateExpectedTransactionalityRepository }
  from './domain/repository/create-expected-transactionality-repository';

const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

export default function setupAddress(container: Container) {
  container
    // eslint-disable-next-line max-len
    .bind<CreateExpectedTransactionalityRepository>(TYPES.CREATE_EXPECTED_TRANSACTIONALITY_REPOSITORY)
    .to(
      withDummies
        ? CreateExpectedTransactionalityDummyRepository
        : CreateExpectedTransactionalityHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<CreateExpectedTransactionalityCommand>(TYPES.CREATE_EXPECTED_TRANSACTIONALITY_COMMAND)
    .to(CreateExpectedTransactionalityCommand)
    .inSingletonScope();
}
