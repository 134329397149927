import TYPES from '@/types';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import { CustomerAddressEntity } from '../../../domain/entities/customer-address-entity';
import { SearchAddressRepository } from '../../../domain/repository/search-address-repository';

@Injectable()
export default class SearchAddressFetchRepository implements SearchAddressRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/address/`;

  get(): Promise<Array<CustomerAddressEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint)
        .then((response) => {
          response.json()
            .then(({ data, success, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
