import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import { UpdatePersonDto } from '../../domain/dtos/update-person-dto';
import { UpdatePersonRepository } from '../../domain/repository/update-person-repository';
import TYPES from '../../types';

@Injectable()
export default class UpdatePersonCommand extends Command<UpdatePersonDto> {
  @Inject(TYPES.ONBOARDING_UPDATE_PERSON_REPOSITORY)
  private readonly repository!: UpdatePersonRepository;

  internalExecute(person: UpdatePersonDto) {
    return this.repository.update(person);
  }
}
