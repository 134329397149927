import TYPES from '@/modules/shared/types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import { CreateCustomerAgreementRepository }
  from '../../domain/repositories/create-customer-agreement-repository';
import { CustomerAgreementEntity } from '../../domain/entities/customer-agreement-entity';

@Injectable()
class CreateCustomerAgreementHttpRepository implements CreateCustomerAgreementRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/customer-agreement/`;

  create(customer_agreement: CustomerAgreementEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint, 'POST', customer_agreement)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}

export default CreateCustomerAgreementHttpRepository;
