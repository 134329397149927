import fixtures from './fixtures.json';
import Injectable from '@/modules/shared/domain/di/injectable';
import { CustomerEmailRepository } from '../../../domain/repository/customer-email-repository';

@Injectable()
export default class CustomerEmailDummyRepository implements CustomerEmailRepository {
  search() {
    return Promise.resolve(fixtures);
  }
}
