import TYPES from '@/types';

// Application
import GetAuthenticationStateService
  from '@/modules/authentication/application/services/get-authentication-state-service';
import CloseSessionWhenTokenExpiresJob
  from '@/modules/authentication/application/jobs/close-session-when-token-expires-job';

// Domain
import Inject from '@/modules/shared/domain/di/inject';

export default class ExpiredSessionViewModel {
  @Inject(TYPES.GET_AUTHENTICATION_STATE_SERVICE)
  private readonly get_authentication_state_service!: GetAuthenticationStateService;

  @Inject(TYPES.CLOSE_SESSION_WHEN_TOKEN_EXPIRES_JOB)
  readonly close_session_when_token_expires_job!: CloseSessionWhenTokenExpiresJob;

  is_open = false;

  get login_path() {
    return process.env.VUE_APP_COLUMBUS_WEB_LOGIN_URL;
  }

  get is_auth() {
    return this.get_authentication_state_service.get().is_auth;
  }
}
