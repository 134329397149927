import { Container } from 'inversify-props';
import TYPES from './types';
import { GetApproximateValuesQueryService } from './application/queries';
import VueStateManager from './infrastructure/state/vue-state-manager';
import ApproximateValueFetchRepository
  from './infrastructure/repository/approximate-value-fetch-repository';
import ApproximateValueDummyRepository
  from './infrastructure/repository/approximate-value-dummy-repository';
import { StateManager } from './domain/state/state-manager';
import { ApproximateValueRepository } from './domain/repository';

const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

export default function setupApproximateValue(container: Container) {
  container
    .bind<StateManager>(TYPES.APPROXIMATE_VALUE_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<ApproximateValueRepository>(TYPES.APPROXIMATE_VALUE_REPOSITORY)
    .to(
      withDummies
        ? ApproximateValueDummyRepository
        : ApproximateValueFetchRepository,
    )
    .inSingletonScope();

  container
    .bind<GetApproximateValuesQueryService>(TYPES.GET_APPROXIMATE_VALUES_QUERY_SERVICE)
    .to(GetApproximateValuesQueryService)
    .inSingletonScope();
}
