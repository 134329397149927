














import { Component, Vue, Watch } from 'vue-property-decorator';
import NotificationGroups from '@/vue-app/components/notifications/notification-groups.vue';
import NoInternetConnection from '@/vue-app/components/notifications/no-internet-connection.vue';
import ExpiredSession from '@/vue-app/components/authentication/ExpiredSession.vue';
import Error from '@/vue-app/components/notifications/Error.vue';
import NotificationLoader from '@/vue-app/components/notifications/notification-loader.vue';
import AppViewModel from '@/vue-app/view-models/app-view-model';
import NotificationInfo from '@/vue-app/components/notifications/notification-info.vue';

@Component({
  name: 'App',
  components: {
    NotificationInfo,
    NotificationGroups,
    NoInternetConnection,
    ExpiredSession,
    Error,
    NotificationLoader,
  },
})
export default class App extends Vue {
  app_view_model = Vue.observable(new AppViewModel());

  get prevent_exit() {
    return this.$store.state.app.prevent_exit;
  }

  @Watch('prevent_exit')
  onPreventExitChange(new_prevent_exit: boolean) {
    // eslint-disable-next-line consistent-return
    window.onbeforeunload = () => {
      if (new_prevent_exit) {
        return 'Estamos procesando un par de cosas, reintente en unos segundos...';
      }
    };
  }

  created() {
    this.app_view_model.initialize();
  }
}
