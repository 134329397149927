import TYPES from '../../types';

// DOMAIN
import { ValidatePasswordDto } from '@/modules/authentication/domain/dtos/validate-password-dto';
import { ValidatePasswordEntity } from '@/modules/authentication/domain/entities/validate-password-entity';
import { PasswordRepository } from '@/modules/authentication/domain/repositories/password-repository';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class ValidatePasswordQuery extends Query<
  Promise<ValidatePasswordEntity>,
  ValidatePasswordDto
> {
  @Inject(TYPES.PASSWORD_REPOSITORY)
  private readonly password_repository!: PasswordRepository;

  internalExecute(payload: ValidatePasswordDto): Promise<ValidatePasswordEntity> {
    return this.password_repository.validatePassword(payload);
  }
}
