import SHARED_TYPES from '@/modules/shared/types';

// Domain
import { CustomerDocumentRepository } from '../../../domain/repositories';
import { VerifyMagicLinkResponse, UpdateCustomerDocumentFile } from '../../../domain/dtos';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class RestCustomerDocumentRepository implements CustomerDocumentRepository {
  @Inject(SHARED_TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  verify_magic_link(document_type: string, token: string): Promise<VerifyMagicLinkResponse> {
    const endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/customer-documents/${document_type}/update/magic-link/verify/`;

    return new Promise((resolve, reject) => {
      this.http_connector.execute(endpoint, 'POST', { token })
        .then((response) => {
          response.json()
            .then(({ data, success, message }) => {
              console.log(data);
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            })
            .catch(reject);
        }).catch(reject);
    });
  }

  updateFile(update_customer_document_file: UpdateCustomerDocumentFile): Promise<void> {
    const endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/customer-documents/${update_customer_document_file.document_type.name}/`;

    return new Promise((resolve, reject) => {
      this.http_connector.execute(endpoint, 'PUT', {
        token: update_customer_document_file.token,
        file: update_customer_document_file.file,
      })
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json()
              .then(({ message }) => {
                reject(new Error(message));
              });
          }
        })
        .catch(reject);
    });
  }

  downloadBase64CustomerDocument(customer_document_id: string): Promise<string> {
    const endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/customer-documents/download/${customer_document_id}/`;

    return new Promise((resolve, reject) => {
      this.http_connector
        .execute(endpoint, 'GET')
        .then((response) => {
          response
            .json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
