import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import TYPES from '@/types';
import { CreateDocumentRepository, DocumentTypeEnum } from '../../../domain/repository/create-document-repository';
import { CreateDocumentDto } from '../../../domain/dtos/create-document-dto';

@Injectable()
export default class CreateDocumentHttpRepository implements CreateDocumentRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/{DOCUMENT_TYPE}/`;

  execute(document: CreateDocumentDto, document_type: DocumentTypeEnum): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.path.replace('{DOCUMENT_TYPE}', document_type), 'POST', document)
        .then((response) => {
          response.json().then(({ message, success }) => {
            if (success) {
              resolve();
            } else {
              reject(new Error(message));
            }
          });
        })
        .catch(reject);
    });
  }
}
