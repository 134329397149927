import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import { SourceOfResourcesRepository } from '../../../domain/repository';
import fixtures from './fixtures.json';

@Injectable()
export default class SourceOfResourcesDummyRepository implements SourceOfResourcesRepository {
  findAll() {
    return Promise.resolve(fixtures);
  }
}
