import { Route, NavigationGuardNext } from 'vue-router';

// Application
import VerifyCustomerProofOfAddressMagicLinkToken from '@/modules/onboarding/customer-document/application/services/proof-of-address/verify-customer-proof-of-address-magic-link-token';

export default class UpdateProofOfAddressController {
  static beforeIndexEnter(to: Route, from: Route, next: NavigationGuardNext) {
    const { token } = to.query;

    if (!token) next({ name: 'PageNotFound' });

    const verify_customer_document_bank_statement_magic_link_token = (
      new VerifyCustomerProofOfAddressMagicLinkToken()
    );

    verify_customer_document_bank_statement_magic_link_token.execute(token as string)
      .then(() => {
        next();
      })
      .catch(() => {
        next({ name: 'PageNotFound' });
      });
  }
}
