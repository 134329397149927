import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import { JwtRepository } from '../../../domain/repositories/jwt-repository';
import TYPES from '@/types';

@Injectable()
export default class JwtHttpRepository implements JwtRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/auth/validate-jwt/`;

  create(token?: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (token || sessionStorage.getItem('authorization')) {
        this.http_connector.execute(this.path, 'POST', {
          jwt: token || sessionStorage.getItem('authorization'),
        })
          .then((response) => {
            response.json().then(({ success, message, data }) => {
              if (success) {
                resolve(data.is_valid);
              } else {
                reject(new Error(message));
              }
            });
          })
          .catch(reject);
      } else {
        reject(new Error('Token not provided'));
      }
    });
  }
}
