import TYPES from '@/modules/shared/types';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import { StateRepository } from '../../domain/repository';
import { StateEntity } from '../../domain/entities/state-entity';

@Injectable()
export default class StateFetchRepository implements StateRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/catalogs/states/search/{COUNTRY_ID}?sort_field=NOMBRE_ESTADO`;

  findAll(country_id: number): Promise<Array<StateEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint.replace('{COUNTRY_ID}', country_id.toString()))
        .then((response) => {
          response.json()
            .then(({ data, success, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
