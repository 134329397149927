import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import InterviewsDummyRepository
  from './infrastructure/repositories/interviews-dummy-repository';
import InterviewsHttpRepository
  from './infrastructure/repositories/interviews-http-repository';

// Application
import CreateOnBoardingInterviewCommand
  from './application/commands/create-on-boarding-interview-command';

// Domain
import { InterviewsRepository } from './domain/repositories/interviews-repository';

const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

export default function setupInterviews(container: Container) {
  container
    .bind<InterviewsRepository>(TYPES.ON_BOARDING_INTERVIEWS_REPOSITORY)
    .to(
      with_dummies
        ? InterviewsDummyRepository
        : InterviewsHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<CreateOnBoardingInterviewCommand>(TYPES.CREATE_ON_BOARDING_INTERVIEW_COMMAND)
    .to(CreateOnBoardingInterviewCommand)
    .inSingletonScope();
}
