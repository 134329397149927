import TYPES from '@/types';

// DOMAIN
import { PasswordRepository } from '@/modules/authentication/domain/repositories/password-repository';
import { ValidatePasswordRecoveryDto } from '@/modules/authentication/domain/dtos/validate-password-recovery-dto';
import { ValidatePasswordRecoveryEntity } from '@/modules/authentication/domain/entities/validate-password-recovery-entity';
import { RecoverPasswordDto } from '@/modules/authentication/domain/dtos/recover-password-dto';
import { ValidatePasswordEntity } from '@/modules/authentication/domain/entities/validate-password-entity';
import { ValidatePasswordDto } from '@/modules/authentication/domain/dtos/validate-password-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Injectable from '@/modules/shared/domain/di/injectable';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class PasswordHttpRepository implements PasswordRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/auth/password`;

  validatePasswordRecovery(
    payload: ValidatePasswordRecoveryDto,
  ): Promise<ValidatePasswordRecoveryEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector
        .execute(`${this.endpoint}/validate-password-recovery/`, 'POST', payload)
        .then((response) => {
          response.json().then(({ success, message, data }) => {
            if (success) {
              resolve(data);
            } else {
              reject(new Error(message));
            }
          });
        })
        .catch(reject);
    });
  }

  recoverPassword(payload: RecoverPasswordDto): Promise<void> {
    const body = {
      password: payload.password,
      password_confirmation: payload.password_confirmation,
    };
    const headers = {
      Authorization: `Bearer ${payload.token}`,
      'Content-Type': 'application/json',
    };
    return new Promise((resolve, reject) => {
      this.http_connector
        .execute(`${this.endpoint}/recovery/`, 'PATCH', body, 3, headers)
        .then(() => {
          resolve();
        })
        .catch(reject);
    });
  }

  validatePassword(payload: ValidatePasswordDto): Promise<ValidatePasswordEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector
        .execute(`${this.endpoint}/validator/`, 'POST', payload)
        .then((response) => {
          resolve(response.json());
        })
        .catch(reject);
    });
  }
}
