import RESEND_VERIFY_NIP_TYPES from '@/modules/renew-verify-nip/types';

// Domain
import { RenewVerifyNipRepository } from '@/modules/renew-verify-nip/domain/repository/renew-verify-nip-repository';
import { ResendVerifyNipDto } from '@/modules/renew-verify-nip/domain/dtos';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Command from '@/modules/shared/domain/services/command';

@Injectable()
export default class RenewVerifyNipCommandService extends Command<ResendVerifyNipDto> {
  @Inject(RESEND_VERIFY_NIP_TYPES.RENEW_VERIFY_NIP_REPOSITORY)
  private readonly resend_verify_nip_repository!: RenewVerifyNipRepository;

  internalExecute(resend_verify_nip_dto: ResendVerifyNipDto): Promise<any> {
    return this.resend_verify_nip_repository.create(resend_verify_nip_dto);
  }
}
