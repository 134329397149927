import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import { CreatePhoneDto } from '../../domain/dtos/create-phone-dto';
import { CustomerPhoneEntity } from '../../domain/entities/customer-phone-entity';
import { CreatePhoneRepository } from '../../domain/repository/create-phone-repository';
import { PhoneRepositoryStateManager as StateManager } from '../../domain/state/state-manager';

@Injectable()
export default class CreatePhoneCommand extends Command<CreatePhoneDto> {
  @Inject(TYPES.CREATE_PHONE_REPOSITORY)
  private readonly repository!: CreatePhoneRepository;

  @Inject(TYPES.ONBOARDING_PHONE_REPOSITORY_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  internalExecute(phone: CreatePhoneDto): Promise<void> {
    return new Promise((resolve, reject) => {
      this.repository.execute(phone)
        .then(() => {
          const new_phone: CustomerPhoneEntity = {
            customer_phone_id: phone.customer_phone_id,
            customer_phone_default: phone.default,
            phone: {
              ...phone,
              extension: '',
            },
          };

          this.state_manager.patch({ phones: [...this.state_manager.state.phones, new_phone] });
          resolve();
        })
        .catch(reject);
    });
  }
}
