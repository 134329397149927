import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import { SearchPhonesRepository } from '../../../domain/repository/search-phones-repository';

@Injectable()
export default class SearchPhonesDummyRepository implements SearchPhonesRepository {
  async findAll() {
    return Promise.resolve([
      {
        customer_phone_id: 'negiwuewnigwubngeiwegn',
        customer_phone_default: true,
        phone: {
          id: 'weiwjegiwengiwn',
          name: 'house',
          number: '5553569874',
          country_code: '+52',
        },
      },
      {
        customer_phone_id: 'oefmw9u3tj9328tj923n',
        customer_phone_default: true,
        phone: {
          id: '2o3im293fim293ifm3m',
          name: 'office',
          number: '9517539515',
          country_code: '+52',
        },
      },
    ]);
  }
}
