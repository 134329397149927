import SHARED_TYPES from '@/modules/shared/types';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import { CustomerGeolocationsRepository } from '../../domain/repositories/customer-geolocations-repository';
import { CreateCustomerGeolocationDto } from '../../domain/dtos/create-customer-geolocation-dto';

@Injectable()
export default class CustomerGeolocationsHttpRepository implements CustomerGeolocationsRepository {
  @Inject(SHARED_TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  create(customer_geolocation: CreateCustomerGeolocationDto): Promise<void> {
    const path = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/customer-geolocation/`;

    return new Promise((resolve, reject) => {
      this.http_connector.execute(path, 'POST', customer_geolocation)
        .then((response) => {
          response.json()
            .then(({ success, message }) => {
              if (success) {
                resolve();
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
