import Injectable from '@/modules/shared/domain/di/injectable';
import { GeolocationReferencesRepository } from '../../../../domain/repositories/geolocation-references-repository';
import fixtures from './fixtures.json';

@Injectable()
class GeolocationReferencesDummyRepository implements GeolocationReferencesRepository {
  search() {
    return Promise.resolve(fixtures);
  }
}

export default GeolocationReferencesDummyRepository;
