import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import GeolocationReferencesDummyRepository
  from './infrastructure/repositories/geolocation-references/geolocation-references-dummy-repository';
import GeolocationReferencesHttpRepository
  from './infrastructure/repositories/geolocation-references/geolocation-references-http-repository';

// Application
import OnCurrentPositionSubscription
  from './application/subscriptions/on-current-position-subscription';
import SearchGeolocationReferencesQuery
  from './application/queries/search-geolocation-references-query';
import GeolocationService from './application/services/geolocation-service';

// Domain
import { StateManager } from './domain/state/state-manager';
import { GeolocationReferencesRepository } from './domain/repositories/geolocation-references-repository';

const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

export default function setupGeolocation(container: Container) {
  container
    .bind<StateManager>(TYPES.GEOLOCATION_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<OnCurrentPositionSubscription>(TYPES.ON_CURRENT_POSITION_SUBSCRIPTION)
    .to(OnCurrentPositionSubscription)
    .inSingletonScope();

  container
    .bind<GeolocationReferencesRepository>(TYPES.GEOLOCATION_REFERENCES_REPOSITORY)
    .to(
      with_dummies
        ? GeolocationReferencesDummyRepository
        : GeolocationReferencesHttpRepository,
    ).inSingletonScope();

  container
    .bind<SearchGeolocationReferencesQuery>(TYPES.SEARCH_GEOLOCATION_REFERENCES_QUERY)
    .to(SearchGeolocationReferencesQuery)
    .inSingletonScope();

  container
    .bind<GeolocationService>(TYPES.GEOLOCATION_SERVICE)
    .to(GeolocationService)
    .inSingletonScope();
}
