import TYPES from '../../types';

// Domain
import { StateManager } from '../../domain/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class ResetIdentityVerificationStateManager {
  @Inject(TYPES.IDENTITY_VERIFICATION_STATUS_SUBSCRIPTION_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  execute() {
    this.state_manager.patch({
      mati_flow_id: '',
      success: false,
      name: '',
      step: 0,
      percent: 0,
      user_id: '',
      description: '',
      message: '',
      has_problem: false,
      status: '',
    });
  }
}
