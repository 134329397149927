import { CurrentUserRepository } from '../../../domain/repositories/current-user-repository';
import { UserEntity } from '../../../domain/entities/user-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import fixtures from './fixtures.json';

@Injectable()
export default class CurrentUserDummyRepository implements CurrentUserRepository {
  get(): Promise<UserEntity> {
    return Promise.resolve(fixtures);
  }
}
