import fixtures from './fixtures.json';

// Domain
import { EmailRepository } from '../../../domain/repositories/email-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class EmailDummyRepository implements EmailRepository {
  exists(email: string): Promise<boolean> {
    const email_exists = fixtures.some(
      (item) => item.email_address === email,
    );

    return Promise.resolve(email_exists);
  }
}
