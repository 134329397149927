import { Container } from 'inversify-props';
import TYPES from './types';
import VueStateManager from './infrastructure/state/vue-state-manager';
import { ResidenceDummyRepository, ResidenceFetchRepository } from './infrastructure/repository';
import { GetResidencesQueryService } from './application/queries';
import { StateManager } from './domain/state/state-manager';
import { ResidenceRepository } from './domain/repository/residence-repository';

const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

export default function setupResidence(container: Container) {
  container
    .bind<StateManager>(TYPES.ONBOARDING_CATALOG_RESIDENCE_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<ResidenceRepository>(TYPES.RESIDENCE_REPOSITORY)
    .to(
      withDummies
        ? ResidenceDummyRepository
        : ResidenceFetchRepository,
    )
    .inSingletonScope();

  container
    .bind<GetResidencesQueryService>(TYPES.GET_RESIDENCESE_QUERY_SERVICE)
    .to(GetResidencesQueryService)
    .inSingletonScope();
}
