import Injectable from '@/modules/shared/domain/di/injectable';
import { SearchAgreementsRepository } from '../../../domain/repositories/search-agreements-repository';
import { AgreementEntity } from '../../../domain/entities/agreement-entity';
import fixtures from './fixtures.json';

@Injectable()
export default class SearchAgreementsDummyRepository implements SearchAgreementsRepository {
  execute(): Promise<Array<AgreementEntity>> {
    return Promise.resolve(fixtures);
  }
}
