import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Query from '@/modules/shared/domain/services/query';
import TYPES from '@/modules/onboarding/catalogs/residence/types';
import { StateManager } from '../../domain/state/state-manager';
import { ResidenceRepository } from '../../domain/repository/residence-repository';
import { ResidenceEntity } from '../../domain/entities/residence-entity';

@Injectable()
export default class GetResidencesQueryService extends Query<Promise<Array<ResidenceEntity>>> {
  @Inject(TYPES.ONBOARDING_CATALOG_RESIDENCE_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.RESIDENCE_REPOSITORY)
  private readonly repository!: ResidenceRepository;

  internalExecute(): Promise<Array<ResidenceEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.items.length < 1) {
        this.repository.findAll()
          .then((items) => {
            this.state_manager.patch({ items });
            resolve(items);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.items);
      }
    });
  }
}
