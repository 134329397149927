import TYPES from '../../../../types';

// Domain
import { UpdateCustomerDocumentFile } from '../../../../domain/dtos';
import { CustomerDocumentRepository } from '../../../../domain/repositories';
import Command from '@/modules/shared/domain/use-cases/command';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class UpdateCustomerDocumentFileCommand extends Command<UpdateCustomerDocumentFile> {
  @Inject(TYPES.ONBOARDING_CUSTOMER_DOCUMENT_REPOSITORY)
  private readonly customer_document_repository!: CustomerDocumentRepository;

  internalExecute(update_customer_document_file: UpdateCustomerDocumentFile): Promise<void> {
    return this.customer_document_repository.updateFile(update_customer_document_file);
  }
}
