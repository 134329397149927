import fixtures from './fixtures.json';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import { StateRepository } from '../../../domain/repository';
import { StateEntity } from '../../../domain/entities/state-entity';

@Injectable()
export default class StateDummyRepository implements StateRepository {
  findAll(country_id: number): Promise<Array<StateEntity>> {
    return new Promise((resolve, reject) => {
      const states = fixtures.filter((state) => state.country_id === country_id);

      if (states.length === 0) {
        reject(new Error('Not Found'));
      } else {
        resolve(states);
      }
    });
  }
}
