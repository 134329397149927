import POLICIES from '../security/policies';

export default {
  path: '/reset-password',
  component: () => import('@/vue-app/layouts/Layout.vue'),
  meta: {
    access_policy: POLICIES.UNAUTHENTICATED,
  },
  children: [
    {
      name: 'ResetPassword',
      path: '/',
      component: () => import('@/vue-app/components/onboarding/reset-password/ResetPassword.vue'),
    },
  ],
};
