import TYPES from '../../types';

// Domain
import { StateManager } from '../../domain/state/state-manager';
import Inject from '@modules/shared/domain/di/inject';
import Injectable from '@modules/shared/domain/di/injectable';

@Injectable()
export default class CloseSessionWhenTokenExpiresJob {
  @Inject(TYPES.AUTHENTICATION_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  private interval?: NodeJS.Timer;

  perform_later = () => {
    if (!this.interval) {
      const expiration_datetime = sessionStorage.getItem('session_til');
      const authentication_token = sessionStorage.getItem('authorization');

      if (expiration_datetime && authentication_token) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const expiration_parsed_datetime = new Date(expiration_datetime!);
        // eslint-disable-next-line
        // @ts-ignore
        const minutes_til_expiration = expiration_parsed_datetime - new Date();

        if (minutes_til_expiration >= 0) {
          this.interval = setTimeout(() => {
            this.endSession();
          }, minutes_til_expiration);
        } else {
          this.endSession();
        }
      } else if (authentication_token) {
        this.endSession();
      }
    }
  }

  private endSession() {
    this.state_manager.patch({ is_auth: false });
    sessionStorage.clear();
  }
}
