import Vue from 'vue';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';
import { PhoneRepositoryStateManager } from '../../domain/state/state-manager';
import PhoneRepositoryState from '../../domain/state/state';

@Injectable()
export default class PhoneRepositoryVueStateManager implements PhoneRepositoryStateManager {
  private _state: PhoneRepositoryState
    = Vue.observable<PhoneRepositoryState>(new PhoneRepositoryState());

  private readonly observers: Observer[] = [];

  get state(): PhoneRepositoryState {
    return this._state;
  }

  set state(value: PhoneRepositoryState) {
    this._state = value;
    this.notifyAll();
  }

  patch(new_state: Partial<PhoneRepositoryState>) {
    if (new_state.phones) {
      this.state.loaded_phones = true;
      this.state.phones = new_state.phones;
    }

    if (new_state.cell_phones) {
      this.state.loaded_cell_phones = true;
      this.state.cell_phones = new_state.cell_phones;
    }
  }

  notifyAll() {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer) {
    this.observers.push(observer);
  }
}
