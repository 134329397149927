import LoginController from '@/vue-app/controllers/login-controller';

export default {
  path: '/login',
  component: () => import('@/vue-app/layouts/Layout.vue'),
  children: [
    {
      path: '/',
      name: 'Login',
      beforeEnter: LoginController.beforeIndexEnter,
      component: () => import('@/vue-app/views/Login.vue'),
    },
  ],
};
