import TYPES from '../../../types';

// Domain
import { CustomerDocumentRepository } from '../../../domain/repositories';
import { InvalidCustomerDocumentBankStatementMagicLinkToken } from '../../../domain/exceptions';
import Command from '@/modules/shared/domain/use-cases/command';
import Inject from '@/modules/shared/domain/di/inject';

export default class VerifyCustomerDocumentBankStatementMagicLinkToken extends Command<string> {
  @Inject(TYPES.ONBOARDING_CUSTOMER_DOCUMENT_REPOSITORY)
  private readonly customer_document_repository!: CustomerDocumentRepository;

  internalExecute(token: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.customer_document_repository.verify_magic_link('bank-statement', token)
        .then(({ valid }) => {
          if (valid) {
            resolve();
          } else {
            reject(
              new InvalidCustomerDocumentBankStatementMagicLinkToken('Invalid magic link token'),
            );
          }
        })
        .catch(reject);
    });
  }
}
