import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import { CustomerCellphoneEntity } from '../../domain/entities/customer-cellphone-entity';
import { CreateCellphoneDto } from '../../domain/dtos/create-cellphone-dto';
import { CreateCellPhoneRepository } from '../../domain/repository/create-cell-phone-repository';
import { PhoneRepositoryStateManager as StateManager } from '../../domain/state/state-manager';

@Injectable()
export default class CreateCellPhoneCommand extends Command<CreateCellphoneDto> {
  @Inject(TYPES.CREATE_CELL_PHONE_REPOSITORY)
  private readonly repository!: CreateCellPhoneRepository;

  @Inject(TYPES.ONBOARDING_PHONE_REPOSITORY_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  internalExecute(cell_phone: CreateCellphoneDto): Promise<unknown> {
    return new Promise((resolve, reject) => {
      this.repository.execute(cell_phone)
        .then(() => {
          const new_cellphone: CustomerCellphoneEntity = {
            customer_cellphone_id: cell_phone.customer_cellphone_id,
            cellphone: {
              ...cell_phone,
              extension: '',
            },
          };

          this.state_manager.patch({
            cell_phones: [...this.state_manager.state.cell_phones, new_cellphone],
          });
          resolve();
        })
        .catch(reject);
    });
  }
}
