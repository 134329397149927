import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import TYPES from '@/types';
import { UpdatePersonRepository } from '../../../domain/repository/update-person-repository';
import { UpdatePersonDto } from '../../../domain/dtos/update-person-dto';

@Injectable()
export default class PersonHttpRepository implements UpdatePersonRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/person/`;

  update(person: UpdatePersonDto): Promise<void> {
    return new Promise((resolve, reject) => {
      const person_patch = { ...person };
      delete person_patch.id;
      this.http_connector.execute(`${this.path}${person.id}/`, 'PATCH', person_patch)
        .then(() => {
          resolve();
        })
        .catch(reject);
    });
  }
}
