// eslint-disable-next-line import/no-cycle
import { Link } from './link';
import Injectable from '../dependency_injection/injectable';

@Injectable()
export default class EmptyLink implements Link {
  setNext(): Link {
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  next(): void {}
}
