import { Container } from 'inversify-props';
import TYPES from './types';
import VueStateManager from './infrastructure/state/vue-state-manager';
import { CountryDummyRepository } from './infrastructure/repository';
import { GetCountriesQueryService } from './application/queries';
import { StateManager } from './domain/state/state-manager';
import { CountryRepository } from './domain/repository';

export default function setupCountry(container: Container) {
  container
    .bind<StateManager>(TYPES.ONBOARDING_CATALOG_COUNTRY_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<CountryRepository>(TYPES.COUNTRY_REPOSITORY)
    .to(CountryDummyRepository)
    .inSingletonScope();

  container
    .bind<GetCountriesQueryService>(TYPES.GET_COUNTRIES_QUERY_SERVICE)
    .to(GetCountriesQueryService)
    .inSingletonScope();
}
