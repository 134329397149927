import { Container } from 'inversify-props';
import { SuburbRepository } from './domain/repository';
import { SuburbDummyRepository, SuburbHttpRepository } from './infrastructure/repository';
import { GetSuburbsInZipCodeQuery } from './application/queries';
import { SuburbsInZipCodeStateManager } from './domain/state-manager/suburbs-in-zip-code';
import SuburbsInZipCodeVueStateManager from './infrastructure/state-manager/suburbs-in-zip-code-vue';
import TYPES from './types';

const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

export default function setupSuburbCatalog(container: Container) {
  container
    .bind<SuburbRepository>(TYPES.SUBURB_REPOSITORY)
    .to(
      withDummies
        ? SuburbDummyRepository
        : SuburbHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SuburbsInZipCodeStateManager>(TYPES.SUBURBS_IN_ZIP_CODE_STATE_MANAGER)
    .to(SuburbsInZipCodeVueStateManager)
    .inSingletonScope();

  container
    .bind<GetSuburbsInZipCodeQuery>(TYPES.GET_SUBURBS_IN_ZIP_CODE_QUERY)
    .to(GetSuburbsInZipCodeQuery)
    .inSingletonScope();
}
