import { CustomerPhoneEntity } from '../entities/customer-phone-entity';
import { CustomerCellphoneEntity } from '../entities/customer-cellphone-entity';

export default class PhoneRepositoryState {
  loaded_phones = false;

  phones: Array<CustomerPhoneEntity> = [];

  loaded_cell_phones = false;

  cell_phones: Array<CustomerCellphoneEntity> = [];
}
