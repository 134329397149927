export default {
  LOGGER: Symbol.for('LOGGER'),
  WINDOW: Symbol.for('WINDOW'),
  RUNNER: Symbol.for('RUNNER'),
  EXECUTOR_LINK: Symbol.for('EXECUTOR_LINK'),
  LOGGER_LINK: Symbol.for('LOGGER_LINK'),
  UTIL_FUNCTIONS: Symbol.for('UTIL_FUNCTIONS'),
  WEBSOCKET_BUS: Symbol.for('WEBSOCKET_BUS'),
  HTTP_CONNECTOR: Symbol.for('HTTP_CONNECTOR'),
  DATETIME_VALUE: Symbol.for('DATETIME_VALUE'),
  NOTIFIER: Symbol.for('NOTIFIER'),
  INTERNET_STATUS_BUS: Symbol.for('INTERNET_STATUS_BUS'),
  GEOLOCATION: Symbol.for('GEOLOCATION'),
  PERMISSIONS: Symbol.for('PERMISSIONS'),
};
