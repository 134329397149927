import { Container } from 'inversify-props';
import TYPES from './types';
import VueStateManager from './infrastructure/state/vue-state-manager';
import RelationshipFetchRepository from './infrastructure/repository/relationship-fetch-repository';
import RelationshipDummyRepository from './infrastructure/repository/relationship-dummy-repository';
import { GetRelationshipsQueryService } from './application/queries';
import { StateManager } from './domain/state/state-manager';
import { RelationshipRepository } from './domain/repository';

const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

export default function setupRelationship(container: Container) {
  container
    .bind<StateManager>(TYPES.ONBOARDING_CATALOGS_RELATIONSHIP_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<RelationshipRepository>(TYPES.RELATIONSHIP_REPOSITORY)
    .to(
      withDummies
        ? RelationshipDummyRepository
        : RelationshipFetchRepository,
    )
    .inSingletonScope();

  container
    .bind<GetRelationshipsQueryService>(TYPES.GET_RELATIONSHIPS_QUERY_SERVICE)
    .to(GetRelationshipsQueryService)
    .inSingletonScope();
}
