import { Route, NavigationGuardNext } from 'vue-router';
import POLICIES from './policies';

// Application
import { ValidateJwtCommand } from '@/modules/authentication/application/commands';
import SignInService from '@/modules/authentication/application/services/sign-in-service';

// eslint-disable-next-line consistent-return
export default function authorizeAccess(to: Route, from: Route, next: NavigationGuardNext) {
  const matched_route_with_access_policy = to.matched.find((route) => route.meta.access_policy);

  if (!matched_route_with_access_policy) return next();

  const validateJwtCommand = new ValidateJwtCommand();
  const { access_policy } = matched_route_with_access_policy.meta;

  validateJwtCommand.execute()
    .then(() => {
      const sign_in_service = new SignInService();

      sign_in_service.login();

      if (access_policy === POLICIES.AUTHENTICATED) return next();

      return next({ path: '/onboarding' });
    })
    .catch(() => {
      if (access_policy === POLICIES.UNAUTHENTICATED) return next();

      return next({ path: '/sign-up' });
    });
}
