import { Container } from 'inversify-props';
import TYPES from './types';
import VueStateManager from './infrastructure/state/vue-state-manager';
import ProfessionFetchRepository from './infrastructure/repository/profession-fetch-repository';
import ProfessionDummyRepository from './infrastructure/repository/profession-dummy-repository';
import { GetProfessionsQueryService } from './application/queries';
import { StateManager } from './domain/state/state-manager';
import { ProfessionRepository } from './domain/repository';

const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

export default function setupProfession(container: Container) {
  container
    .bind<StateManager>(TYPES.ONBOARDING_CATALOGS_PROFESSION_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<ProfessionRepository>(TYPES.PROFESSION_REPOSITORY)
    .to(
      withDummies
        ? ProfessionDummyRepository
        : ProfessionFetchRepository,
    )
    .inSingletonScope();

  container
    .bind<GetProfessionsQueryService>(TYPES.GET_PROFESSIONS_QUERY_SERVICE)
    .to(GetProfessionsQueryService)
    .inSingletonScope();
}
