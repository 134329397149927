import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';
import { StepEntity } from '../../domain/entities/step-entity';
import { StateManager } from '../../domain/state/state-manager';

@Injectable()
export default class GetOnboardingStepQuery extends Query<Promise<StepEntity>, string> {
  @Inject(TYPES.ONBOARDING_STATUS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  internalExecute(step_name: string): Promise<StepEntity> {
    const existent_step = this.state_manager.state.steps.find(
      (item) => item.current_step === step_name,
    );

    if (existent_step) {
      return Promise.resolve(existent_step);
    }

    return Promise.reject(new Error(step_name));
  }
}
