import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import { SuburbRepository } from '../../../domain/repository';
import { SuburbInZipCodeEntity } from '../../../domain/entities';
import fixtures from './fixtures.json';

@Injectable()
export default class SuburbDummyRepository implements SuburbRepository {
  findInZipCode(): Promise<Array<SuburbInZipCodeEntity>> {
    return new Promise((resolve) => {
      resolve(fixtures);
    });
  }
}
