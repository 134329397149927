import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import { CreateInterviewDto } from '../../domain/data_transfer_objects/create_interview_dto';
import { InterviewsRepository } from '../../domain/repositories/interviews-repository';

@Injectable()
export default class CreateOnBoardingInterviewCommand extends Command<CreateInterviewDto> {
  @Inject(TYPES.ON_BOARDING_INTERVIEWS_REPOSITORY)
  private readonly repository!: InterviewsRepository;

  internalExecute(create_interview_dto: CreateInterviewDto): Promise<void> {
    return this.repository.create(create_interview_dto);
  }
}
