import TYPES from '../../types';

// Domain
import { StepEntity } from '../../domain/entities/step-entity';
import { StateManager } from '@/modules/onboarding/status/domain/state/state-manager';
import { OnboardingCreateStepRepository }
  from '../../domain/repositories/onboarding-create-step-repository';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateOnboardingStepCommand extends Command<StepEntity> {
  @Inject(TYPES.ONBOARDING_STATUS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.ONBOARDING_CREATE_STEP_REPOSITORY)
  private readonly repository!: OnboardingCreateStepRepository;

  internalExecute(step: StepEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      const existent_step = this.state_manager.state.steps.find(
        (item) => item?.current_step === step.current_step,
      );

      if (!existent_step) {
        this.repository.execute(step)
          .then(() => {
            this.state_manager.patch({
              steps: [...this.state_manager.state.steps, step],
            });
            resolve();
          })
          .catch(reject);
      } else {
        resolve();
      }
    });
  }
}
