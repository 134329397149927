import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import { PhoneEntity } from '../../domain/entities/phone-entity';
import { UpdateCellPhoneRepository } from '../../domain/repository/update-cell-phone-repository';
import { PhoneRepositoryStateManager as StateManager } from '../../domain/state/state-manager';

@Injectable()
export default class UpdateCellPhoneCommand extends Command<PhoneEntity> {
  @Inject(TYPES.UPDATE_CELL_PHONE_REPOSITORY)
  private readonly repository!: UpdateCellPhoneRepository;

  @Inject(TYPES.ONBOARDING_PHONE_REPOSITORY_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  internalExecute(cell_phone: PhoneEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.repository.execute(cell_phone)
        .then(() => {
          const { cell_phones } = this.state_manager.state;
          const cellPhoneIndex = cell_phones.findIndex(
            (item) => item.cellphone.id === cell_phone.id,
          );
          cell_phones[cellPhoneIndex].cellphone = cell_phone;
          this.state_manager.patch({ cell_phones });
          resolve();
        })
        .catch(reject);
    });
  }
}
