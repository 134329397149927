import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import { CreateOnBoardingDto } from '../../domain/dtos/create-on-boarding-dto';
import { CreateOnBoardingRepository } from '../../domain/repository/create-on-boarding-repository';

@Injectable()
export default class CreateAddressCommand extends Command<CreateOnBoardingDto> {
  @Inject(TYPES.CREATE_ON_BOARDING_REPOSITORY)
  private readonly repository!: CreateOnBoardingRepository;

  internalExecute(on_boarding: CreateOnBoardingDto): Promise<void> {
    return new Promise((resolve, reject) => {
      this.repository.execute(on_boarding)
        .then(() => {
          resolve();
        })
        .catch(reject);
    });
  }
}
