import { VueConstructor } from 'vue';
import VueTheMask from 'vue-the-mask';
import money from '@titou10/v-money';
import Notifications from 'vue-notification';
import { VueReCaptcha } from 'vue-recaptcha-v3';

import TYPES from '../types';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import { setupFrameBuster } from '@/utils/browser';

// Domain
import Injectable from '@modules/shared/domain/dependency_injection/injectable';
import Inject from '@modules/shared/domain/dependency_injection/inject';

@Injectable()
export default class Application {
  @Inject(TYPES.VUE)
  private readonly vue!: VueConstructor;

  constructor() {
    setupFrameBuster();
  }

  create(app: VueConstructor) {
    this.vue.config.productionTip = false;
    this.vue.use(VueTheMask);
    this.vue.use(Notifications);
    this.vue.use(money, {
      decimal: '.',
      thousands: ',',
      prefix: '$ ',
      precision: 2,
      masked: false,
    });
    this.vue.use(VueReCaptcha, {
      siteKey: process.env.VUE_APP_RE_CAPTCHA_KEY,
      loaderOptions: {
        useRecaptchaNet: true,
      },
    });

    // eslint-disable-next-line new-cap
    return new this.vue({
      router,
      store,
      vuetify,
      render: (create) => create(app),
      i18n,
    }).$mount('#app');
  }
}
