import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Command from '@/modules/shared/domain/services/command';
import VERIFY_NIP_TYPES from '@/modules/verify-nip/types';
import { VerifyNipRepository } from '@/modules/verify-nip/domain/repository/verify-nip-repository';
import { VerifyNipDto } from '../../domain/dtos';

@Injectable()
export default class VerifyNipCommandService extends Command<VerifyNipDto> {
  @Inject(VERIFY_NIP_TYPES.VERIFY_NIP_REPOSITORY)
  private readonly verify_nip_repository!: VerifyNipRepository;

  internalExecute(verify_nip_dto: VerifyNipDto): Promise<any> {
    return this.verify_nip_repository.create(verify_nip_dto);
  }
}
