import TYPES from '../../types';
import { v4 } from 'uuid';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Service from '@/modules/shared/domain/services/service';
import CreateCellPhoneCommand from '../commands/create-cell-phone-command';
import UpdateCellPhoneCommand from '../commands/update-cell-phone-command';
import { CustomerCellphoneEntity } from '../../domain/entities/customer-cellphone-entity';

@Injectable()
class CreateOrUpdateCellPhoneService extends Service<Promise<void>, CustomerCellphoneEntity> {
  @Inject(TYPES.CREATE_CELL_PHONE_COMMAND)
  private readonly createCellPhoneCommand!: CreateCellPhoneCommand;

  @Inject(TYPES.UPDATE_CELL_PHONE_COMMAND)
  private readonly updateCellPhoneCommand!: UpdateCellPhoneCommand;

  readonly = false;

  internalExecute(cell_phone: CustomerCellphoneEntity): Promise<void> {
    if (cell_phone.cellphone.id) {
      return this.updateCellPhoneCommand.execute(cell_phone.cellphone) as Promise<void>;
    }

    const id = v4();

    return this.createCellPhoneCommand.execute({
      id,
      number: cell_phone.cellphone.number,
      name: cell_phone.cellphone.name,
      country_code: cell_phone.cellphone.country_code,
      customer_cellphone_id: id,
      default: true,
      confirmed: false,
    }) as Promise<void>;
  }
}

export default CreateOrUpdateCellPhoneService;
