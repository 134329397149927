import fixtures from './fixtures.json';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import { SearchAddressRepository } from '../../../../domain/repository/search-address-repository';
import { CustomerAddressEntity } from '../../../../domain/entities/customer-address-entity';

@Injectable()
export default class SearchAddressDummyRepository implements SearchAddressRepository {
  async get(): Promise<Array<CustomerAddressEntity>> {
    return Promise.resolve(fixtures);
  }
}
