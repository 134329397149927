import TYPES from '@/modules/shared/types';
import { CurrentUserRepository } from '../../domain/repositories/current-user-repository';
import { UserEntity } from '../../domain/entities/user-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class CurrentUserHttpRepository implements CurrentUserRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  get(): Promise<UserEntity> {
    const path = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/users/me/`;

    return new Promise((resolve, reject) => {
      this.http_connector.execute(path)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
