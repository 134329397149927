import POLICIES from '../security/policies';
import UpdateBankStatementController from '@/vue-app/controllers/update-bank-statement-controller';

export default {
  path: '/update-bank-statement',
  component: () => import('@/vue-app/layouts/Layout.vue'),
  meta: {
    access_policy: POLICIES.UNAUTHENTICATED,
  },
  children: [
    {
      name: 'UpdateBankStatement',
      path: '/',
      beforeEnter: UpdateBankStatementController.beforeIndexEnter,
      component: () => import('@/vue-app/views/UpdateBankStatement.vue'),
    },
  ],
};
