import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import { ExpectedTransactionalityEntity } from '../../domain/entities/expected-transactionality-entity';
import { CreateExpectedTransactionalityRepository }
  from '../../domain/repository/create-expected-transactionality-repository';

@Injectable()
class CreateExpectedTransactionalityCommand extends Command<ExpectedTransactionalityEntity> {
  @Inject(TYPES.CREATE_EXPECTED_TRANSACTIONALITY_REPOSITORY)
  private readonly repository!: CreateExpectedTransactionalityRepository;

  internalExecute(expected_transactionality: ExpectedTransactionalityEntity) {
    return new Promise((resolve, reject) => {
      this.repository.execute(expected_transactionality)
        .then(() => {
          resolve();
        })
        .catch(reject);
    });
  }
}

export default CreateExpectedTransactionalityCommand;
