import { Container } from 'inversify-props';
import { setupAdapterAccordingToBrowser } from '@/utils/browser';
import TYPES from './types';

// Infrastructure
import UtilFunctions from './infrastructure/utils/functions';
import SocketIoBus from './infrastructure/bus/web-socket/socket-io-bus';
import { FetchHttpConnector } from './infrastructure/connectors/http';
import LuxonDatetimeValue from './infrastructure/value-objects/luxon-datetime-value';
import { VueNotifier } from './infrastructure/notifiers/message_notifier';
import ChromePermissions from './infrastructure/permissions/chrome-permissions';
import DefaultPermissions from './infrastructure/permissions/default-permissions';
import InternetStatusNavigatorBus
  from './infrastructure/bus/internet-status/internet-status-navigator-bus';
import NavigatorGeolocation from './infrastructure/geolocation/navigator-geolocation';

// Domain
import Runner from './domain/runner';
import ExecutorLink from './domain/runner/executor-link';
import { MessageNotifier } from './domain/notifiers/message_notifier';
import { WebSocketBus } from './domain/bus/web-socket-bus';
import { InternetStatusBus } from './domain/bus/internet-status-bus';
import { HttpConnector } from './domain/connectors/http';
import DatetimeValue from './domain/value-objects/datetime-value';
import { Geolocation } from './domain/geolocation';
import { Permissions } from './domain/permissions';

// eslint-disable-next-line import/prefer-default-export
export default function setupSharedModule(container: Container) {
  container
    .bind<Runner>(TYPES.RUNNER)
    .to(Runner)
    .inSingletonScope();

  container
    .bind<ExecutorLink>(TYPES.EXECUTOR_LINK)
    .to(ExecutorLink)
    .inSingletonScope();

  container
    .bind<UtilFunctions>(TYPES.UTIL_FUNCTIONS)
    .to(UtilFunctions)
    .inSingletonScope();

  container
    .bind<WebSocketBus>(TYPES.WEBSOCKET_BUS)
    .to(SocketIoBus)
    .inSingletonScope();

  container
    .bind<HttpConnector>(TYPES.HTTP_CONNECTOR)
    .to(FetchHttpConnector)
    .inSingletonScope();

  container
    .bind<DatetimeValue>(TYPES.DATETIME_VALUE)
    .to(LuxonDatetimeValue)
    .inRequestScope();

  container
    .bind<MessageNotifier>(TYPES.NOTIFIER)
    .to(VueNotifier)
    .inSingletonScope();

  container
    .bind<InternetStatusBus>(TYPES.INTERNET_STATUS_BUS)
    .to(InternetStatusNavigatorBus)
    .inRequestScope();

  container
    .bind<Geolocation>(TYPES.GEOLOCATION)
    .to(NavigatorGeolocation)
    .inSingletonScope();

  container
    .bind<Permissions>(TYPES.PERMISSIONS)
    .to(setupAdapterAccordingToBrowser({
      chrome: ChromePermissions,
      edge_chromium: ChromePermissions,
      default: DefaultPermissions,
    }))
    .inSingletonScope();
}
