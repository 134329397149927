import TYPES from '@/types';

// Domain
import { PasswordRepository } from '@/modules/authentication/domain/repositories/password-repository';
import { ValidatePasswordRecoveryDto } from '@/modules/authentication/domain/dtos/validate-password-recovery-dto';
import Command from '@/modules/shared/domain/use-cases/command';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class ValidatePasswordRecoveryCommand extends Command<ValidatePasswordRecoveryDto> {
  @Inject(TYPES.PASSWORD_REPOSITORY)
  private readonly password_repository!: PasswordRepository;

  internalExecute(payload: ValidatePasswordRecoveryDto): Promise<unknown> {
    return this.password_repository.validatePasswordRecovery(payload);
  }
}
