import { Container } from 'inversify-props';
import TYPES from './types';
import { SourceOfResourcesRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';
import SourceOfResourcesDummyRepository
  from './infrastructure/repository/source-of-resources-dummy-repository';
import SourceOfResourcesFetchRepository
  from './infrastructure/repository/source-of-resources-fetch-repository';
import VueStateManager from './infrastructure/state/vue-state-manager';
import { GetSourcesOfResourcesQueryService } from './application/queries';

const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

export default function setupSourceOfResources(container: Container) {
  container
    .bind<StateManager>(TYPES.SOURCE_OF_RESOURCES_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<SourceOfResourcesRepository>(TYPES.SOURCE_OF_RESOURCES_REPOSITORY)
    .to(
      withDummies
        ? SourceOfResourcesDummyRepository
        : SourceOfResourcesFetchRepository,
    )
    .inSingletonScope();

  container
    .bind<GetSourcesOfResourcesQueryService>(TYPES.GET_SOURCES_OF_RESOURCES_QUERY_SERVICE)
    .to(GetSourcesOfResourcesQueryService)
    .inSingletonScope();
}
