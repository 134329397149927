import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import {
  NationalityFetchRepository,
  NationalityDummyRepository,
} from './infrastructure/repository';

// Application
import { GetNationalitiesQueryService } from './application/queries';

// Domain
import { StateManager } from './domain/state/state-manager';
import { NationalityRepository } from './domain/repository';

export default function setupNationality(container: Container) {
  const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<StateManager>(TYPES.ONBOARDING_CATALOG_NATIONALITY_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<NationalityRepository>(TYPES.NATIONALITY_REPOSITORY)
    .to(
      with_dummies
        ? NationalityDummyRepository
        : NationalityFetchRepository,
    )
    .inSingletonScope();

  container
    .bind<GetNationalitiesQueryService>(TYPES.GET_NATIONALITIES_QUERY_SERVICE)
    .to(GetNationalitiesQueryService)
    .inSingletonScope();
}
