import TYPES from '@/types';

// DOMAIN
import { PasswordChangedStatusEntity } from '@/modules/authentication/domain/entities/password-changed-status-entity';
import { WebSocketBus } from '@/modules/shared/domain/bus/web-socket-bus';
import Subscription from '@/modules/shared/domain/use-cases/subscription';
import Inject from '@/modules/shared/domain/di/inject';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class PasswordChangedStatusSubscription extends Subscription {
  @Inject(TYPES.WEBSOCKET_BUS)
  private readonly bus!: WebSocketBus;

  private readonly connection_path = `${process.env.VUE_APP_WEBSOCKET_URL}/app.auth.user.password.frontend.1.changed/`;

  internalExecute(): void {
    this.bus.connect(this.connection_path, (event) => {
      const state: PasswordChangedStatusEntity = event.attributes;

      sessionStorage.setItem('origin_url', state.origin_url);

      this.close();
    });
  }

  close(): void {
    this.bus.disconnect();
  }
}
