import TYPES from '../../types';
import { v4 } from 'uuid';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Service from '@/modules/shared/domain/services/service';
import CreatePhoneCommand from '../commands/create-phone-command';
import UpdatePhoneCommand from '../commands/update-phone-command';
import { CustomerPhoneEntity } from '../../domain/entities/customer-phone-entity';

@Injectable()
class CreateOrUpdatePhoneService extends Service<Promise<void>, CustomerPhoneEntity> {
  @Inject(TYPES.CREATE_PHONE_COMMAND)
  private readonly createPhoneCommand!: CreatePhoneCommand;

  @Inject(TYPES.UPDATE_PHONE_COMMAND)
  private readonly updatePhoneCommand!: UpdatePhoneCommand;

  readonly = false;

  internalExecute(phone: CustomerPhoneEntity): Promise<void> {
    if (phone.phone.id) {
      return this.updatePhoneCommand.execute(phone.phone) as Promise<void>;
    }

    const id = v4();

    return this.createPhoneCommand.execute({
      id,
      number: phone.phone.number,
      name: phone.phone.name,
      country_code: phone.phone.country_code,
      customer_phone_id: id,
      default: phone.customer_phone_default,
      confirmed: false,
      extension: '',
    }) as Promise<void>;
  }
}

export default CreateOrUpdatePhoneService;
