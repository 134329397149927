import { Container } from 'inversify-props';
import TYPES from '@/types';

// INFRASTRUCTURE
import ProofOfAddressDummyRepository from '@/modules/onboarding/proof-of-address/infrastructure/repositories/proof-of-address-dummy-repository';
import ProofOfAddressHttpRepository from '@/modules/onboarding/proof-of-address/infrastructure/repositories/proof-of-address-http-repository';

// APPLICATION
import { UseIneAsProofOfAddressCommand } from '@/modules/onboarding/proof-of-address/application/commands';

// DOMAIN
import { ProofOfAddressRepository } from '@/modules/onboarding/proof-of-address/domain/repositories/proof-of-address-repository';

export default function setupProofOfAddressModule(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<ProofOfAddressRepository>(TYPES.PROOF_OF_ADDRESS_REPOSITORY)
    .to(withDummies ? ProofOfAddressDummyRepository : ProofOfAddressHttpRepository)
    .inSingletonScope();

  container
    .bind<UseIneAsProofOfAddressCommand>(TYPES.USE_INE_AS_PROOF_OF_ADDRESS_COMMAND)
    .to(UseIneAsProofOfAddressCommand)
    .inSingletonScope();
}
