/* eslint-disable max-len */

import Query from '@modules/shared/domain/use-cases/query';
import Injectable from '@modules/shared/domain/di/injectable';
import Inject from '@modules/shared/domain/di/inject';
import { IdentityVerificationServiceType } from '../../domain/types';
import TYPES from '../../types';
import { IdentityVerificationServiceRepository } from '../../domain/repositories';

@Injectable()
export default class GetIdentityVerificationServiceQuery extends Query<Promise<IdentityVerificationServiceType>> {
  constructor(
    @Inject(TYPES.IDENTITY_VERIFICATION_SERVICE_REPOSITORY) private readonly identityVerificationServiceRepository: IdentityVerificationServiceRepository,
  ) {
    super();
  }

  internalExecute() {
    return this.identityVerificationServiceRepository.get('dab000f4-35e7-40b8-93c1-81fd9da426ba');
  }
}
