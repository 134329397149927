/* eslint-disable import/no-cycle */

import Injectable from '../dependency_injection/injectable';
import Inject from '../dependency_injection/inject';
import SHARED_TYPES from '../../types';
import ExecutorLink from './executor-link';
import Service from '../services/service';

@Injectable()
export default class Runner {
  chain = this.executorLink;

  // eslint-disable-next-line no-useless-constructor
  constructor(
    @Inject(SHARED_TYPES.EXECUTOR_LINK) private readonly executorLink: ExecutorLink,
  ) {}

  // eslint-disable-next-line class-methods-use-this
  run(service: Service<unknown, unknown>, param: unknown): unknown {
    const context = { service, result: undefined, param };
    this.chain.next(context);

    return context.result;
  }
}
