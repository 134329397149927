import Vue from 'vue';

// DOMAIN
import { PromotorStateManager } from '@/modules/onboarding/catalogs/promotor/domain/state/promotor-state-manager';
import PromotorState from '@/modules/onboarding/catalogs/promotor/domain/state/promotor-state';
import Observer from '@/modules/shared/domain/observer/observer';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class PromotorVueStateManager implements PromotorStateManager {
  private _state: PromotorState = Vue.observable<PromotorState>(new PromotorState());

  private readonly observers: Observer[] = [];

  get state(): PromotorState {
    return this._state;
  }

  set state(value: PromotorState) {
    this._state = value;
    this.notifyAll();
  }

  patch(new_state: Partial<PromotorState>) {
    if (new_state.items && new_state.search_term) {
      this.state.loaded = true;
      this.state.items = new_state.items;
      this.state.search_term = new_state.search_term;
    }
  }

  notifyAll() {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer) {
    this.observers.push(observer);
  }
}
