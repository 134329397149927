import TYPES from '@/modules/shared/types';

// DOMAIN
import { PromotorRepository } from '@/modules/onboarding/catalogs/promotor/domain/repositories/promotor-repository';
import { PromotorEntity } from '@/modules/onboarding/catalogs/promotor/domain/entities/promotor-entity';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class PromotorHttpRepository implements PromotorRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  searchPromotor(search_term: string): Promise<Array<PromotorEntity>> {
    const endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/catalogs/promotor/?search_term=${search_term}`;

    return new Promise((resolve, reject) => {
      this.http_connector
        .execute(endpoint)
        .then((response) => response.json())
        .then(({ success, message, data }) => {
          if (success) {
            resolve(data);
          } else {
            reject(new Error(message));
          }
        })
        .catch(reject);
    });
  }
}
