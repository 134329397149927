import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import { CreateCustomerAgreementRepository }
  from '../../domain/repositories/create-customer-agreement-repository';
import { CustomerAgreementEntity } from '../../domain/entities/customer-agreement-entity';

@Injectable()
class OnboardingCreateCustomerAgreementCommand extends Command<CustomerAgreementEntity> {
  @Inject(TYPES.ONBOARDING_CREATE_CUSTOMER_AGREEMENT_REPOSITORY)
  private readonly repository!: CreateCustomerAgreementRepository;

  internalExecute(customer_agreement: CustomerAgreementEntity): Promise<unknown> {
    return this.repository.create(customer_agreement);
  }
}

export default OnboardingCreateCustomerAgreementCommand;
