import { Route, NavigationGuardNext } from 'vue-router';

// Application
import { VerifyCustomerDocumentBankStatementMagicLinkToken }
  from '@/modules/onboarding/customer-document/application/services/bank-statement';

export default class UpdateBankStatementController {
  static beforeIndexEnter(to: Route, from: Route, next: NavigationGuardNext) {
    const { token } = to.query;

    if (!token) next({ name: 'PageNotFound' });

    const verify_customer_document_bank_statement_magic_link_token = (
      new VerifyCustomerDocumentBankStatementMagicLinkToken()
    );

    verify_customer_document_bank_statement_magic_link_token.execute(token as string)
      .then(() => {
        next();
      })
      .catch(() => {
        next({ name: 'PageNotFound' });
      });
  }
}
