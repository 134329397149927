import TYPES from '@/modules/shared/types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import { CustomerEmailRepository } from '../../domain/repository/customer-email-repository';
import { CustomerEmailEntity } from '../../domain/entities/email-entity';

@Injectable()
export default class CustomerEmailHttpRepository implements CustomerEmailRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  search(): Promise<Array<CustomerEmailEntity>> {
    const endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/emails/`;

    return new Promise((resolve, reject) => {
      this.http_connector.execute(endpoint)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
