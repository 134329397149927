import TYPES from '@/modules/shared/types';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import { CountryRepository } from '../../domain/repository';
import { CountryEntity } from '../../domain/entities/country-entity';

@Injectable()
export default class CountryFetchRepository implements CountryRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/catalogs/countries/`;

  findAll(): Promise<Array<CountryEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
