import TYPES from '../../types';

// Domain
import { StateManager } from '../../domain/state/state-manager';
import Inject from '@/modules/shared/domain/di/inject';

export default class SignInService {
  @Inject(TYPES.AUTHENTICATION_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  login = (token?: string) => {
    if (token) {
      const session_length = process.env.VUE_APP_SESSION_LENGTH
        ? parseInt(process.env.VUE_APP_SESSION_LENGTH, 10)
        : 30;
      const token_expiration_date = new Date();

      token_expiration_date.setMinutes(token_expiration_date.getMinutes() + session_length);
      sessionStorage.setItem('session_til', token_expiration_date.toString());
      sessionStorage.setItem('authorization', token);
    }

    this.state_manager.patch({ is_auth: true });
  }
}
