import TYPES from '../../types';
import { v4 } from 'uuid';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Service from '@/modules/shared/domain/services/service';
import CreateAddressCommand from '../commands/create-address-command';
import UpdateAddresscommand from '../commands/update-address-command';
import { CustomerAddressEntity } from '../../domain/entities/customer-address-entity';

@Injectable()
export default class CreateOrUpdateAddress extends Service<Promise<void>, CustomerAddressEntity> {
  @Inject(TYPES.CREATE_ADDRESS_COMMAND)
  private readonly createAddressCommand!: CreateAddressCommand;

  @Inject(TYPES.UPDATE_ADDRESS_COMMAND)
  private readonly updateAddressCommand!: UpdateAddresscommand;

  readonly = false;

  internalExecute(address: CustomerAddressEntity): Promise<void> {
    if (address.address.id) {
      return this.updateAddressCommand.execute(address.address) as Promise<void>;
    }

    const id = v4();

    return this.createAddressCommand.execute({
      address_type: address.address.address_type,
      zip_code: address.address.zip_code,
      state: address.address.state,
      city: address.address.city,
      municipality: address.address.municipality,
      settlement: address.address.settlement,
      street: address.address.street,
      external_number: address.address.external_number,
      interior_number: address.address.interior_number,
      customer_address_id: id,
      default: address.customer_address_default,
      country_id: address.address.country.id,
      proof_of_address_id: address.customer_address_proof_of_address_id,
      id,
    }) as Promise<void>;
  }
}
