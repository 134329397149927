import Injectable from '../../../domain/di/injectable';
import { InternetStatusBus } from '../../../domain/bus/internet-status-bus';
import { BusCallback } from '../../../domain/bus/bus-callback';

@Injectable()
export default class InternetStatusNavigatorBus implements InternetStatusBus {
  connect(callback: BusCallback) {
    window.addEventListener('online', () => {
      callback({ onLine: navigator.onLine });
    });
    window.addEventListener('offline', () => {
      callback({ onLine: navigator.onLine });
    });
  }

  disconnect() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    window.removeEventListener('online', () => {});
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    window.removeEventListener('offline', () => {});
  }
}
