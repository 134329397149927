import TYPES from '../../types';

// Domain
import { CustomerEmailEntity } from '../../domain/entities/email-entity';
import { StateManager } from '../../domain/state/state-manager';
import { CustomerEmailRepository } from '../../domain/repository/customer-email-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class OnBoardingSearchCustomerEmailsQuery extends Query<Array<CustomerEmailEntity>> {
  @Inject(TYPES.ON_BOARDING_CUSTOMER_EMAIL_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.ON_BOARDING_CUSTOMER_EMAIL_REPOSITORY)
  private readonly repository!: CustomerEmailRepository;

  internalExecute() {
    if (this.state_manager.state.items.length < 1) {
      this.repository.search()
        .then((items) => {
          this.state_manager.patch({ items });
        });
    }

    return this.state_manager.state.items;
  }
}
