// DOMAIN
import { PromotorEntity } from '@/modules/onboarding/catalogs/promotor/domain/entities/promotor-entity';

export default class PromotorState {
  loaded = false;

  search_term = '';

  items: Array<PromotorEntity> = [];
}
