import Injectable from '@/modules/shared/domain/di/injectable';
import { OnboardingCreateStepRepository }
  from '../../../../domain/repositories/onboarding-create-step-repository';
import { StepEntity } from '../../../../domain/entities/step-entity';

@Injectable()
export default class OnboardingCreateStepDummyRepository implements OnboardingCreateStepRepository {
  execute(step: StepEntity): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const steps_data = JSON.parse(sessionStorage.getItem('steps_data')!) || [];
    sessionStorage.setItem('steps_data', JSON.stringify([step, ...steps_data]));

    return Promise.resolve();
  }
}
