export default {
  GET_PHONES_QUERY_SERVICE: Symbol.for('GET_PHONES_QUERY_SERVICE'),
  SEARCH_PHONES_REPOSITORY: Symbol.for('SEARCH_PHONES_REPOSITORY'),
  ONBOARDING_PHONE_REPOSITORY_STATE_MANAGER: Symbol.for('ONBOARDING_PHONE_REPOSITORY_STATE_MANAGER'),
  SEARCH_CELL_PHONES_REPOSITORY: Symbol.for('SEARCH_CELL_PHONES_REPOSITORY'),
  GET_CELL_PHONES_QUERY: Symbol.for('GET_CELL_PHONES_QUERY'),
  CREATE_PHONE_REPOSITORY: Symbol.for('CREATE_PHONE_REPOSITORY'),
  CREATE_PHONE_COMMAND: Symbol.for('CREATE_PHONE_COMMAND'),
  CREATE_OR_UPDATE_PHONE_SERVICE: Symbol.for('CREATE_OR_UPDATE_PHONE_SERVICE'),
  UPDATE_PHONE_REPOSITORY: Symbol.for('UPDATE_PHONE_REPOSITORY'),
  UPDATE_PHONE_COMMAND: Symbol.for('UPDATE_PHONE_COMMAND'),
  UPDATE_CELL_PHONE_REPOSITORY: Symbol.for('UPDATE_CELL_PHONE_REPOSITORY'),
  CREATE_CELL_PHONE_REPOSITORY: Symbol.for('CREATE_CELL_PHONE_REPOSITORY'),
  CREATE_CELL_PHONE_COMMAND: Symbol.for('CREATE_CELL_PHONE_COMMAND'),
  UPDATE_CELL_PHONE_COMMAND: Symbol.for('UPDATE_CELL_PHONE_COMMAND'),
  CREATE_OR_UPDATE_CELL_PHONE_SERVICE: Symbol.for('CREATE_OR_UPDATE_CELL_PHONE_SERVICE'),
};
