export default {
  IDENTITY_VERIFICATION_SERVICE_REPOSITORY:
    Symbol.for('IDENTITY_VERIFICATION_SERVICE_REPOSITORY'),
  GET_IDENTITY_VERIFICATION_SERVICE_QUERY: Symbol.for('IDENTITY_VERIFICATION_SERVICE_QUERY'),
  GET_IDENTITY_VERIFICATION_STATUS_QUERY: Symbol.for('GET_IDENTITY_VERIFICATION_STATUS_QUERY'),
  IDENTITY_VERIFICATION_STATUS_SUBSCRIPTION:
    Symbol.for('IDENTITY_VERIFICATION_STATUS_SUBSCRIPTION'),
  IDENTITY_VERIFICATION_STATUS_SUBSCRIPTION_STATE_MANAGER:
    Symbol.for('IDENTITY_VERIFICATION_STATUS_SUBSCRIPTION_STATE_MANAGER'),
  IDENTITY_VERIFICATION_RESET_STATE_MANAGER:
    Symbol.for('IDENTITY_VERIFICATION_RESET_STATE_MANAGER'),
};
