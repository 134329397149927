import TYPES from '../../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import SaveSignUpSectionService from './save-onboarding-section/save-sign-up-section-service';
import SavePersonalInformationSectionService
  from './save-onboarding-section/save-personal-information-section-service';
import SaveFinancialInformationSectionService
  from './save-onboarding-section/save-financial-information-section-service';
import SaveTaxInformationSectionService
  from './save-onboarding-section/save-tax-information-section-service';
import SaveBankInformationService
  from './save-onboarding-section/save-bank-information-service';
import SaveBeneficiariesSectionService
  from './save-onboarding-section/save-beneficiaries-section-service';
import SaveConfirmationSectionService
  from './save-onboarding-section/save-confirmation-section-service';
import SaveVerifyNipSectionService
  from './save-onboarding-section/save-verify-nip-section-service';
import UpdateOnboardingStepCommand
  from '@/modules/onboarding/status/application/commands/update-onboarding-step-command';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';
import { StateManager } from '../../../domain/state/state-manager';
import { StoredPromiseDto } from '@/modules/onboarding/status/domain/dtos/stored-promise-dto';

const saveSignUpSectionService = new SaveSignUpSectionService();
const savePersonalInformationSectionService = new SavePersonalInformationSectionService();
const saveFinancialInformationSectionService = new SaveFinancialInformationSectionService();
const saveTaxInformationSectionService = new SaveTaxInformationSectionService();
const saveBankInformationService = new SaveBankInformationService();
const saveBeneficiariesSectionService = new SaveBeneficiariesSectionService();
const saveConfirmationSectionService = new SaveConfirmationSectionService();
const saveVerifyNipSectionService = new SaveVerifyNipSectionService();

@Injectable()
export default class CompleteOnBoardingService {
  @Inject(TYPES.UPDATE_ONBOARDING_STEP_COMMAND)
  private readonly updateOnboardingStepCommand!: UpdateOnboardingStepCommand;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  @Inject(TYPES.ONBOARDING_STATUS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  execute(): Promise<void> {
    return new Promise((resolve, reject) => {
      Promise.all([
        saveSignUpSectionService.execute(),
        saveVerifyNipSectionService.execute(),
        savePersonalInformationSectionService.execute(),
        saveTaxInformationSectionService.execute(),
        saveFinancialInformationSectionService.execute(),
        saveBankInformationService.execute(),
        saveBeneficiariesSectionService.execute(),
        saveConfirmationSectionService.execute(),
      ])
        .then(async (errors) => {
          const current_step = await this.get_on_boarding_step_query.execute('confirmation');
          const merged_errors: Record<string, Array<StoredPromiseDto>> = {};
          let with_error = false;

          errors.forEach((item) => {
            if (item) {
              merged_errors[Object.keys(item)[0]] = item[Object.keys(item)[0]];

              if (item[Object.keys(item)[0]].length) {
                with_error = true;
              }
            }
          });

          await this.updateOnboardingStepCommand.execute({
            id: current_step.id,
            current_step: current_step.current_step,
            payload: {
              ...current_step.payload,
              errors: merged_errors,
            },
          });

          if (with_error) {
            reject(new Error(''));
          } else {
            resolve();
          }
        })
        .catch(reject);
    });
  }
}
