import TYPES from '@/types';

// DOMAIN
import { RecoverPasswordDto } from '@/modules/authentication/domain/dtos/recover-password-dto';
import { PasswordRepository } from '@/modules/authentication/domain/repositories/password-repository';
import Command from '@/modules/shared/domain/use-cases/command';
import Inject from '@/modules/shared/domain/di/inject';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class RecoverPasswordCommand extends Command<RecoverPasswordDto> {
  @Inject(TYPES.PASSWORD_REPOSITORY)
  private readonly password_repository!: PasswordRepository;

  internalExecute(payload: RecoverPasswordDto): Promise<unknown> {
    return this.password_repository.recoverPassword(payload);
  }
}
