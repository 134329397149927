import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';
import { AgreementEntity } from '../../domain/entities/agreement-entity';
import { SearchAgreementsRepository } from '../../domain/repositories/search-agreements-repository';
import { PhoneRepositoryStateManager as StateManager }
  from '../../domain/state/state-manager';

@Injectable()
export default class SearchAgreementsAsyncQuery extends Query<Promise<Array<AgreementEntity>>> {
  @Inject(TYPES.AGREEMENTS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.SEARCH_AGREEMENTS_REPOSITORY)
  private readonly repository!: SearchAgreementsRepository;

  internalExecute(): Promise<Array<AgreementEntity>> {
    return new Promise((resolve, reject) => {
      if (!this.state_manager.state.loaded) {
        this.repository.execute()
          .then((items) => {
            this.state_manager.patch({ items });
            resolve(items);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.items);
      }
    });
  }
}
