import Injectable from '@/modules/shared/domain/di/injectable';
import { EconomicActivityRepository } from '../../../domain/repository';
import { EconomicActivityEntity } from '../../../domain/entities/economic-activity-entity';
import fixtures from './fixtures.json';

@Injectable()
export default class EconomicActivityDummyRepository implements EconomicActivityRepository {
  findAll(): Promise<Array<EconomicActivityEntity>> {
    return Promise.resolve(fixtures);
  }
}
