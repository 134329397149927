import Vue from 'vue';
import Injectable from '../../../domain/di/injectable';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

@Injectable()
// eslint-disable-next-line import/prefer-default-export
export class VueNotifier implements MessageNotifier {
  showSuccessNotification(message: string) {
    Vue.notify({
      group: 'notifications',
      type: 'success',
      title: message,
      duration: 10000,
    });
  }

  showErrorNotification(message: string) {
    Vue.notify({
      group: 'notifications',
      type: 'error',
      title: message,
      duration: 10000,
    });
  }

  showInfoNotification(message: string) {
    Vue.notify({
      group: 'info',
      type: 'info',
      title: message,
      duration: 10000,
    });
  }

  showWarningNotification(message: string) {
    Vue.notify({
      group: 'notifications',
      type: 'warning',
      title: message,
      duration: 10000,
    });
  }

  showLoadingNotification(message: string) {
    Vue.notify({
      group: 'loader',
      title: message,
      duration: -1,
    });
  }

  hideLoadingNotification() {
    Vue.notify({
      group: 'loader',
      clean: true,
    });
  }
}
