import TYPES from '@/types';

// DOMAIN
import { ProofOfAddressRepository } from '@/modules/onboarding/proof-of-address/domain/repositories/proof-of-address-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class ProofOfAddressHttpRepository implements ProofOfAddressRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  useIneAsProofOfAddress(id: string): Promise<void> {
    const endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/proof-of-address/use-ine-front/`;

    return new Promise((resolve, reject) => {
      this.http_connector.execute(endpoint, 'POST', { id })
        .then((response) => {
          response.json().then(({ message, success }) => {
            if (success) {
              resolve();
            } else {
              reject(new Error(message));
            }
          });
        })
        .catch(reject);
    });
  }
}
