import { ActionPayloadType } from '../types';
import { LayoutStateType } from './types';

function toggleGoToSiteOnHeader({ commit }: ActionPayloadType, show_go_to_site?: boolean) {
  commit('toggleGoToSiteOnHeader', show_go_to_site);
}

function changeHeaderPosition({ commit }: ActionPayloadType, header_position: LayoutStateType['header']['position']) {
  commit('changeHeaderPosition', header_position);
}

export default {
  toggleGoToSiteOnHeader,
  changeHeaderPosition,
};
