import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import { SearchCellPhonesRepository } from '../../../domain/repository/search-cell-phones-repository';

@Injectable()
export default class SearchCellPhonesDummyRepository implements SearchCellPhonesRepository {
  async findAll() {
    return Promise.resolve([
      {
        customer_cellphone_id: 'negiwuewnigwubngeiwegn',
        cellphone: {
          id: 'weiwjegiwengiwn',
          name: 'house',
          number: '466456777',
          country_code: '+52',
        },
      },
      {
        customer_cellphone_id: 'oefmw9u3tj9328tj923n',
        cellphone: {
          id: '2o3im293fim293ifm3m',
          name: 'house',
          number: '466256777',
          country_code: '+52',
        },
      },
    ]);
  }
}
