import Injectable from '@/modules/shared/domain/di/injectable';
import { CreateExpectedTransactionalityRepository }
  from '../../../domain/repository/create-expected-transactionality-repository';

@Injectable()
// eslint-disable-next-line max-len
class CreateExpectedTransactionalityDummyRepository implements CreateExpectedTransactionalityRepository {
  execute(): Promise<void> {
    return Promise.resolve();
  }
}

export default CreateExpectedTransactionalityDummyRepository;
