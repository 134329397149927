import TYPES from '@/types';

// DOMAIN
import { RenewVerifyNipRepository } from '@/modules/renew-verify-nip/domain/repository';
import { ResendVerifyNipDto } from '@/modules/renew-verify-nip/domain/dtos';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class RenewVerifyNipHttpRepository implements RenewVerifyNipRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/renew-customer-nip/`;

  create(renew_verify_nip_dto: ResendVerifyNipDto): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.path, 'POST', renew_verify_nip_dto)
        .then((response) => {
          response.json().then(({ message, errors }) => {
            if (!errors) {
              resolve(true);
            } else {
              reject(new Error(message));
            }
          });
        })
        .catch(reject);
    });
  }
}
