import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';
import { StepEntity } from '../../domain/entities/step-entity';
import { OnboardingSearchStepsRepository } from '../../domain/repositories/onboarding-search-steps-repository';
import { StateManager } from '../../domain/state/state-manager';

@Injectable()
export default class OnboardingSearchStepsQuery extends Query<Promise<Array<StepEntity>>> {
  @Inject(TYPES.ONBOARDING_SEARCH_STEPS_REPOSITORY)
  private readonly repository!: OnboardingSearchStepsRepository;

  @Inject(TYPES.ONBOARDING_STATUS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  internalExecute(): Promise<Array<StepEntity>> {
    return new Promise((resolve, reject) => {
      this.repository.execute()
        .then((items) => {
          this.state_manager.patch({ steps: items });

          resolve(items);
        })
        .catch(reject);
    });
  }
}
