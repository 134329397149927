import TYPES from '../../types';

// Domain
import { StepEntity } from '../../domain/entities/step-entity';
import { OnboardingUpdateStepRepository }
  from '../../domain/repositories/onboarding-update-step-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateOnboardingStepCommand extends Command<StepEntity> {
  @Inject(TYPES.ONBOARDING_UPDATE_STEP_REPOSITORY)
  private readonly repository!: OnboardingUpdateStepRepository;

  @Inject(TYPES.ONBOARDING_STATUS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  internalExecute(step: StepEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      const existent_step_index = this.state_manager.state.steps.findIndex(
        (item) => item.current_step === step.current_step,
      );

      if (existent_step_index >= 0) {
        this.repository.execute(step)
          .then(() => {
            const new_steps = this.state_manager.state.steps;

            new_steps[existent_step_index] = step;
            this.state_manager.patch({ steps: new_steps });
            resolve();
          })
          .catch(reject);
      } else {
        reject(new Error('Step to update not found.'));
      }
    });
  }
}
