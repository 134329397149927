import TYPES from '@/modules/shared/types';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import { IdentityVerificationServiceRepository } from '../../domain/repositories';
import { IdentityVerificationServiceType } from '../../domain/types';

@Injectable()
class IdentityVerificationServiceHttpRepository implements IdentityVerificationServiceRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/identity/mati-environment/`;

  get(id: string): Promise<IdentityVerificationServiceType> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}${id}/`)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}

export default IdentityVerificationServiceHttpRepository;
