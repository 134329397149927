import { Container } from 'inversify-props';
import RESEND_VERIFY_NIP_TYPES from './types';

// Infrastructure
import {
  RenewVerifyNipHttpRepository,
  RenewVerifyNipDummyRepository,
} from './infrastructure/repository';

// Application
import { RenewVerifyNipCommandService } from './application/commands';

// Domain
import { RenewVerifyNipRepository } from './domain/repository';

export default function setupRenewVerifyNip(container: Container) {
  const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<RenewVerifyNipRepository>(RESEND_VERIFY_NIP_TYPES.RENEW_VERIFY_NIP_REPOSITORY)
    .to(
      with_dummies
        ? RenewVerifyNipDummyRepository
        : RenewVerifyNipHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<RenewVerifyNipCommandService>(RESEND_VERIFY_NIP_TYPES.RENEW_VERIFY_NIP_COMMAND_SERVICE)
    .to(RenewVerifyNipCommandService)
    .inSingletonScope();
}
