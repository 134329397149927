import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Query from '@/modules/shared/domain/services/query';
import TYPES from '../../types';
import { StateManager } from '../../domain/state/state-manager';
import { SourceOfResourcesRepository } from '../../domain/repository/source-of-resources-repository';
import { SourceOfResourceEntity } from '../../domain/entities/source-of-resource-entity';

@Injectable()
class GetSourcesOfResourcesQueryService extends Query<Array<SourceOfResourceEntity>> {
  @Inject(TYPES.SOURCE_OF_RESOURCES_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.SOURCE_OF_RESOURCES_REPOSITORY)
  private readonly repository!: SourceOfResourcesRepository;

  internalExecute() {
    if (this.state_manager.state.items.length < 1) {
      this.repository.findAll()
        .then((items) => {
          this.state_manager.patch({ items });
        });
    }

    return this.state_manager.state.items;
  }
}

export default GetSourcesOfResourcesQueryService;
