import { Container } from 'inversify-props';
import TYPES from '@/modules/onboarding/catalogs/gender/types';
import VueStateManager from './infrastructure/state/vue-state-manager';
import { GenderDummyRepository } from './infrastructure/repository';
import { GetGendersQueryService } from './application/queries';
import { StateManager } from './domain/state/state-manager';
import { GenderRepository } from './domain/repository';

export default function setupGender(container: Container) {
  container
    .bind<StateManager>(TYPES.ONBOARDING_CATALOG_GENDER_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<GenderRepository>(TYPES.GENDER_REPOSITORY)
    .to(GenderDummyRepository)
    .inSingletonScope();

  container
    .bind<GetGendersQueryService>(TYPES.GET_GENDERS_QUERY_SERVICE)
    .to(GetGendersQueryService)
    .inSingletonScope();
}
