import fixtures from './fixtures.json';

// DOMAIN
import { MaritalStatusRepository } from '@/modules/onboarding/catalogs/marital-status/domain/repositories/marital-status-repository';
import { MaritalStatusEntity } from '@/modules/onboarding/catalogs/marital-status/domain/entities/marital-status-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class MaritalStatusDummyRepository implements MaritalStatusRepository {
  getMaritalStatusItems(): Promise<Array<MaritalStatusEntity>> {
    return Promise.resolve(fixtures);
  }
}
