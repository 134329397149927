import TYPES from '../../types';
import SHARED_TYPES from '@/modules/shared/types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { InternetStatusBus } from '@/modules/shared/domain/bus/internet-status-bus';
import Subscription from '@/modules/shared/domain/use-cases/subscription';
import { StateManager } from '../../domain/state/state-manager';

@Injectable()
export default class InternetStatusSubscription extends Subscription {
  @Inject(TYPES.INTERNET_STATUS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(SHARED_TYPES.INTERNET_STATUS_BUS)
  private readonly bus!: InternetStatusBus;

  internalExecute() {
    this.bus.connect((event) => {
      this.state_manager.patch({ online: event.onLine });
    });
  }

  close() {
    this.bus.disconnect();
  }
}
