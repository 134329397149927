import { Container } from 'inversify-props';
import TYPES from './types';
import CustomerGeolocationsDummyRepository
  from './infrastructure/repositories/customer-geolocations-dummy-repository';
import CustomerGeolocationsHttpRepository
  from './infrastructure/repositories/customer-geolocations-http-repository';
import CreateCustomerGeolocationCommand
  from './application/commands/create-customer-geolocation-command';
import { CustomerGeolocationsRepository }
  from './domain/repositories/customer-geolocations-repository';

const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

export default function setupCustomerGeolocations(container: Container) {
  container
    .bind<CustomerGeolocationsRepository>(TYPES.CUSTOMER_GEOLOCATIONS_REPOSITORY)
    .to(
      with_dummies
        ? CustomerGeolocationsDummyRepository
        : CustomerGeolocationsHttpRepository,
    ).inSingletonScope();

  container
    .bind<CreateCustomerGeolocationCommand>(TYPES.CREATE_CUSTOMER_GEOLOCATION_COMMAND)
    .to(CreateCustomerGeolocationCommand)
    .inSingletonScope();
}
