import { Container } from 'inversify-props';

import TYPES from './types';

// Infrastructure
import { FakeCustomerDocumentRepository } from './infrastructure/repositories';
import { RestCustomerDocumentRepository } from './infrastructure/repositories/rest';
import { CustomerDocumentVueStateManager } from '@/modules/onboarding/customer-document/infrastructure/state-manager';

// Application
import { UpdateCustomerDocumentFileCommand } from './application/services/file/update';
import GetCustomerBase64DocumentQuery from '@/modules/onboarding/customer-document/application/queries/get-customer-base64-document-query';
import UpdateCustomerDocumentStateService from '@/modules/onboarding/customer-document/application/services/file/update-customer-document-state-service';

// Domain
import { CustomerDocumentRepository } from './domain/repositories';
import { CustomerDocumentStateManager } from '@/modules/onboarding/customer-document/domain/state-manager';

export default function setupCustomerDocumentModule(container: Container) {
  const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<CustomerDocumentRepository>(TYPES.ONBOARDING_CUSTOMER_DOCUMENT_REPOSITORY)
    .to(
      with_dummies
        ? FakeCustomerDocumentRepository
        : RestCustomerDocumentRepository,
    ).inSingletonScope();

  container
    .bind<UpdateCustomerDocumentFileCommand>(TYPES.ONBOARDING_UPDATE_CUSTOMER_DOCUMENT_FILE_COMMAND)
    .to(UpdateCustomerDocumentFileCommand)
    .inSingletonScope();

  container
    .bind<CustomerDocumentStateManager>(TYPES.ONBOARDING_CUSTOMER_DOCUMENT_STATE_MANAGER)
    .to(CustomerDocumentVueStateManager)
    .inSingletonScope();

  container
    .bind<GetCustomerBase64DocumentQuery>(TYPES.GET_CUSTOMER_BASE64_DOCUMENT_QUERY)
    .to(GetCustomerBase64DocumentQuery)
    .inSingletonScope();

  container
    .bind<UpdateCustomerDocumentStateService>(TYPES.UPDATE_CUSTOMER_DOCUMENT_STATE_SERVICE)
    .to(UpdateCustomerDocumentStateService)
    .inSingletonScope();
}
