import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import TYPES from '@/types';
import { UpdateCellPhoneRepository } from '../../../domain/repository/update-cell-phone-repository';
import { PhoneEntity } from '../../../domain/entities/phone-entity';

@Injectable()
export default class UpdateCellPoneHttpRepository implements UpdateCellPhoneRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/cellphones/`;

  execute(cellphone: PhoneEntity): Promise<void> {
    const patch_cellphone_data = { ...cellphone };
    delete patch_cellphone_data.id;
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.path.concat(`${cellphone.id}/`), 'PATCH', patch_cellphone_data)
        .then(() => {
          resolve();
        })
        .catch(reject);
    });
  }
}
