import TYPES from '@/types';

// Application
import UpdateStateManagerAndGetUseful
  from '../services/update-state-manager-and-get-useful';

// Domain
import State from '../../domain/state-manager/state';
import { StateManager } from '@/modules/onboarding/identity-verification/domain/state-manager/';
import Injectable from '@modules/shared/domain/di/injectable';
import Inject from '@modules/shared/domain/di/inject';
import Subscription from '@modules/shared/domain/use-cases/subscription';
import { WebSocketBus } from '@modules/shared/domain/bus/web-socket-bus';

const update_state_manager_and_get_useful = new UpdateStateManagerAndGetUseful();

@Injectable()
export default class IdentityVerificationStatusSubscription extends Subscription {
  @Inject(TYPES.IDENTITY_VERIFICATION_STATUS_SUBSCRIPTION_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.WEBSOCKET_BUS)
  private readonly bus!: WebSocketBus;

  private connection_path = `${process.env.VUE_APP_WEBSOCKET_URL}/app.mati_event_dict.web_socket.1.published/`;

  internalExecute() {
    const user_id = sessionStorage.getItem('user_id');

    if (user_id) {
      this.bus.connect(this.connection_path.concat(`${user_id}/`), (event) => {
        const new_state: State = event.attributes.mati_event_dict;

        if ((new_state.percent && this.state_manager.state.percent < new_state.percent)) {
          if (
            update_state_manager_and_get_useful.execute(new_state)
          ) {
            if (new_state.percent === 100) {
              this.close();
            }
          }
        }
      });
    } else {
      throw new Error('user_id can\'t be undefined');
    }
  }

  close() {
    this.bus.disconnect();
  }
}
