import injectable from '@modules/shared/domain/di/injectable';
import { AuthenticationDataRepository } from '../../domain/repositories';

@injectable()
export default class AuthenticationDataDummyRepository implements AuthenticationDataRepository {
  create() {
    return Promise.resolve({
      token: 'test',
    });
  }
}
