import { Route, NavigationGuardNext } from 'vue-router';

// Application
import { ValidateJwtCommand } from '@/modules/authentication/application/commands';
import GetCurrentUserQuery from '@/modules/current-user/application/queries/get-current-user-query';
import SignInService from '@/modules/authentication/application/services/sign-in-service';

export default class LoginController {
  static beforeIndexEnter(to: Route, from: Route, next: NavigationGuardNext) {
    const auth_token = to.query.token || sessionStorage.getItem('authorization');

    if (auth_token) {
      const validate_jwt_command = new ValidateJwtCommand();

      validate_jwt_command.execute(auth_token as string)
        .then(() => {
          const { pending_deposit } = to.query;
          const sign_in_service = new SignInService();

          sign_in_service.login(auth_token as string);

          if (pending_deposit === 'true') {
            next({ path: '/onboarding?pending_deposit=true' });
          } else {
            const getCurrentUserQuery = new GetCurrentUserQuery();

            getCurrentUserQuery.execute()
              .then((user) => {
                sessionStorage.setItem('user_id', user.id);
                next({ path: '/onboarding' });
              })
              .catch(() => {
                sessionStorage.clear();
                next();
              });
          }
        })
        .catch(() => {
          next();
        });
    } else {
      next();
    }
  }
}
