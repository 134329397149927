import { DateTime } from 'luxon';
import Injectable from '../../domain/di/injectable';
import DatetimeValue from '../../domain/value-objects/datetime-value';

@Injectable()
export default class LuxonDatetimeValue extends DatetimeValue {
  private value: DateTime;

  constructor() {
    super();
    this.value = DateTime.utc();
  }

  create(): LuxonDatetimeValue {
    return new LuxonDatetimeValue();
  }

  toString(): string {
    return this.value.toFormat('yyyy-MM-dd\'T\'TTZZ');
  }

  toJSON(): string {
    return this.value.toFormat('yyyy-MM-dd\'T\'TTZZ');
  }
}
