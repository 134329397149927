




























/* eslint-disable */

import { Vue, Component } from 'vue-property-decorator';

@Component({ name: 'Error' })
export default class Error extends Vue {
  is_open = false;

  reloadPage() {
    window.location.reload();
  }

  promiseState(p: Promise<any>) {
    const t = {};
    return Promise.race([p, t])
      .then(v => (v === t)? "pending" : "fulfilled", () => "rejected");
  }

  created() {
    window.addEventListener('executor_link', (event) => {
      // @ts-ignore
      if (typeof event.detail?.result?.then === 'function') {
        const verify_promise_status = setInterval(() => {
          // @ts-ignore
          this.promiseState(event.detail.result).then((state) => {
            if (state !== 'pending') {
              clearInterval(verify_promise_status);
            }

            if (state === 'rejected') {
              this.is_open = true;
            }
          });
        }, 1000);
      }
    });
  }
}
