import fixtures from './fixtures.json';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import { SearchPersonRepository } from '../../../../domain/repository/search-person-repository';

@Injectable()
export default class SearchPersonDummyRepository implements SearchPersonRepository {
  async get() {
    return Promise.resolve(fixtures);
  }
}
