import validate_password_recovery_fixture from './fixtures/validate-password-recovery-fixture.json';
import validate_password_fixture from './fixtures/validate-password-fixture.json';

// Domain
import { PasswordRepository } from '@/modules/authentication/domain/repositories/password-repository';
import { ValidatePasswordRecoveryEntity } from '@/modules/authentication/domain/entities/validate-password-recovery-entity';
import { ValidatePasswordEntity } from '@/modules/authentication/domain/entities/validate-password-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class PasswordDummyRepository implements PasswordRepository {
  validatePasswordRecovery(): Promise<ValidatePasswordRecoveryEntity> {
    return Promise.resolve(validate_password_recovery_fixture);
  }

  recoverPassword(): Promise<void> {
    return Promise.resolve();
  }

  validatePassword(): Promise<ValidatePasswordEntity> {
    return Promise.resolve(validate_password_fixture);
  }
}
