import POLICIES from '@/vue-app/router/security/policies';

export default {
  path: '/onboarding',
  component: () => import('@/vue-app/layouts/Layout.vue'),
  meta: {
    access_policy: POLICIES.AUTHENTICATED,
  },
  children: [
    {
      name: 'OnBoarding',
      path: '/',
      component: () => import('@/vue-app/views/Onboarding.vue'),
    },
  ],
};
