import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import TYPES from '@/types';
import { UpdatePhoneRepository } from '../../../domain/repository/update-phone-repository';
import { PhoneEntity } from '../../../domain/entities/phone-entity';

@Injectable()
export default class UpdatePoneHttpRepository implements UpdatePhoneRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/phones/`;

  update(phone: PhoneEntity): Promise<void> {
    const patch_phone_data = { ...phone };
    delete patch_phone_data.id;
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.path.concat(`${phone.id}/`), 'PATCH', patch_phone_data)
        .then(() => {
          resolve();
        })
        .catch(reject);
    });
  }
}
