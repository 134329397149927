export default {
  GET_ADDRESS_ONBOARDING_QUERY_SERVICE: Symbol.for('GET_ADDRESS_ONBOARDING_QUERY_SERVICE'),
  ONBOARDING_SEARCH_ADDRESS_REPOSITORY: Symbol.for('ONBOARDING_SEARCH_ADDRESS_REPOSITORY'),
  ONBOARDING_UPDATE_ADDRESS_REPOSITORY: Symbol.for('ONBOARDING_UPDATE_ADDRESS_REPOSITORY'),
  UPDATE_ADDRESS_COMMAND: Symbol.for('UPDATE_ADDRESS_COMMAND'),
  ONBOARDING_CATALOG_ADDRESS_STATE_MANAGER:
    Symbol.for('ONBOARDING_CATALOG_ADDRESS_STATE_MANAGER'),
  CREATE_ADDRESS_REPOSITORY: Symbol.for('CREATE_ADDRESS_REPOSITORY'),
  CREATE_ADDRESS_COMMAND: Symbol.for('CREATE_ADDRESS_COMMAND'),
  CREATE_OR_UPDATE_ADDRESS_SERVICE: Symbol.for('CREATE_OR_UPDATE_ADDRESS_SERVICE'),
};
