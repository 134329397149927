import { Container } from 'inversify-props';
import VueStateManager from './infrastructure/state/vue-state-manager';
import EconomicActivityFetchRepository
  from './infrastructure/repository/economic-activity-fetch-repository';
import EconomicActivityDummyRepository
  from './infrastructure/repository/economic-activity-dummy-repository';
import GetEconomicActivitiesQueryService
  from './application/queries/get-economic-activities-query-service';
import { EconomicActivityRepository }
  from './domain/repository/economic-activity-repository';
import { StateManager } from './domain/state/state-manager';
import TYPES from './types';

const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

export default function setupEconomicActivity(container: Container) {
  container
    .bind<StateManager>(TYPES.ONBOARDING_CATALOGS_ECONOMIC_ACTIVITY_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<EconomicActivityRepository>(TYPES.ECONOMIC_ACTIVITY_REPOSITORY)
    .to(
      withDummies
        ? EconomicActivityDummyRepository
        : EconomicActivityFetchRepository,
    )
    .inSingletonScope();

  container
    .bind<GetEconomicActivitiesQueryService>(TYPES.GET_ECONOMIC_ACTIVITIES_QUERY_SERVICE)
    .to(GetEconomicActivitiesQueryService)
    .inSingletonScope();
}
