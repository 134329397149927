import TYPES from '@/types';

// Domain
import { CustomerDocumentRepository } from '@/modules/onboarding/customer-document/domain/repositories';
import Command from '@/modules/shared/domain/use-cases/command';
import Inject from '@/modules/shared/domain/di/inject';

export default class VerifyCustomerProofOfAddressMagicLinkToken extends Command<string> {
  @Inject(TYPES.ONBOARDING_CUSTOMER_DOCUMENT_REPOSITORY)
  private readonly customer_document_repository!: CustomerDocumentRepository;

  internalExecute(token: string): Promise<unknown> {
    return this.customer_document_repository.verify_magic_link('proof-of-address', token);
  }
}
