import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';
import { GeolocationReference } from '../../domain/entities/geolocation-reference';
import { StateManager } from '../../domain/state/state-manager';
import { GeolocationReferencesRepository }
  from '../../domain/repositories/geolocation-references-repository';

@Injectable()
class SearchGeolocationReferencesQuery extends Query<Promise<Array<GeolocationReference>>> {
  @Inject(TYPES.GEOLOCATION_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.GEOLOCATION_REFERENCES_REPOSITORY)
  private readonly repository!: GeolocationReferencesRepository;

  internalExecute(): Promise<Array<GeolocationReference>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.references.loaded) {
        resolve(this.state_manager.state.references.items);
      } else {
        this.repository.search()
          .then((items) => {
            this.state_manager.patch({
              references: {
                items,
                loaded: true,
              },
            });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}

export default SearchGeolocationReferencesQuery;
