import { io, Socket } from 'socket.io-client';
import Injectable from '../../../domain/di/injectable';
import { WebSocketBus } from '../../../domain/bus/web-socket-bus';
import { BusCallback } from '../../../domain/bus/bus-callback';

@Injectable()
export default class SocketIoBus implements WebSocketBus {
  private connection: Socket | null = null;

  connect(path: string, callback: BusCallback) {
    this.connection = io(path, {
      auth: {
        token: sessionStorage.getItem('authorization'),
      },
    });
    this.connection.on('message', callback);
  }

  disconnect() {
    if (this.connection) {
      this.connection.disconnect();
    }
  }
}
