import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import { CreateCustomerGeolocationDto } from '../../domain/dtos/create-customer-geolocation-dto';
import { CustomerGeolocationsRepository } from '../../domain/repositories/customer-geolocations-repository';

@Injectable()
class CreateCustomerGeolocationCommand extends Command<CreateCustomerGeolocationDto> {
  @Inject(TYPES.CUSTOMER_GEOLOCATIONS_REPOSITORY)
  private readonly repository!: CustomerGeolocationsRepository;

  internalExecute(customer_geolocation: CreateCustomerGeolocationDto): Promise<void> {
    return this.repository.create(customer_geolocation);
  }
}

export default CreateCustomerGeolocationCommand;
