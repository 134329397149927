export default {
  ONBOARDING_CURRENT_STEP_REPOSITORY: Symbol.for('ONBOARDING_CURRENT_STEP_REPOSITORY'),
  ONBOARDING_STATUS_STATE_MANAGER: Symbol.for('ONBOARDING_STATUS_STATE_MANAGER'),
  ONBOARDING_CREATE_STEP_REPOSITORY: Symbol.for('ONBOARDING_CREATE_STEP_REPOSITORY'),
  CREATE_ONBOARDING_STEP_COMMAND: Symbol.for('CREATE_ONBOARDING_STEP_COMMAND'),
  ONBOARDING_UPDATE_STEP_REPOSITORY: Symbol.for('UPDATE_ONBOARDING_STEP_REPOSITORY'),
  UPDATE_ONBOARDING_STEP_COMMAND: Symbol.for('UPDATE_ONBOARDING_STEP_COMMAND'),
  ONBOARDING_STEP_REPOSITORY: Symbol.for('ONBOARDING_STEP_REPOSITORY'),
  GET_ONBOARDING_STEP_QUERY: Symbol.for('GET_ONBOARDING_STEP_QUERY'),
  GET_ONBOARDING_STEP_NAME: Symbol.for('GET_ONBOARDING_STEP_NAME'),
  ONBOARDING_SEARCH_STEPS_REPOSITORY: Symbol.for('ONBOARDING_SEARCH_STEPS_REPOSITORY'),
  ONBOARDING_SEARCH_STEPS_QUERY: Symbol.for('ONBOARDING_SEARCH_STEPS_QUERY'),
  COMPLETE_ONBOARDING_SERVICE: Symbol.for('COMPLETE_ONBOARDING_SERVICE'),
};
