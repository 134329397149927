import { LayoutStateType } from './types';

function toggleGoToSiteOnHeader(state: LayoutStateType, show_go_to_site?: boolean) {
  state.header.go_to_site = show_go_to_site ?? !state.header.go_to_site;
}

function changeHeaderPosition(state: LayoutStateType, header_position: LayoutStateType['header']['position']) {
  state.header.position = header_position;
}

export default {
  toggleGoToSiteOnHeader,
  changeHeaderPosition,
};
