import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Command from '@/modules/shared/domain/services/command';
import { AddressEntity } from '../../domain/entities/address-entity';
import { UpdateAddressRepository } from '../../domain/repository/update-address-repository';
import { PhoneRepositoryStateManager as StateManager } from '../../domain/state/state-manager';

@Injectable()
export default class UpdateAddressCommand extends Command<AddressEntity> {
  @Inject(TYPES.ONBOARDING_UPDATE_ADDRESS_REPOSITORY)
  private readonly repository!: UpdateAddressRepository;

  @Inject(TYPES.ONBOARDING_CATALOG_ADDRESS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  internalExecute(address: AddressEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.repository.update(address)
        .then(() => {
          const { items } = this.state_manager.state;
          const addressIndex = items.findIndex((item) => item.address.id === address.id);
          items[addressIndex] = {
            ...items[addressIndex],
            address,
          };
          this.state_manager.patch({ items });
          resolve();
        })
        .catch(reject);
    });
  }
}
