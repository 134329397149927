import fixtures from './fixtures.json';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import { NationalityRepository } from '../../../domain/repository';

@Injectable()
export default class NationalityDummyRepository implements NationalityRepository {
  findAll() {
    return Promise.resolve(fixtures);
  }
}
