import { Container } from 'inversify-props';
import TYPES from './types';

// INFRASTRUCTURE
import MaritalStatusDummyRepository from '@/modules/onboarding/catalogs/marital-status/infrastructure/repositories/marital-status-dummy-repository';
import MaritalStatusHttpRepository from '@/modules/onboarding/catalogs/marital-status/infrastructure/repositories/marital-status-http-repository';
import MaritalStatusVueStateManager from '@/modules/onboarding/catalogs/marital-status/infrastructure/state/marital-status-vue-state-manager';

// APPLICATION
import GetMaritalStatusesQuery from '@/modules/onboarding/catalogs/marital-status/application/queries/get-marital-statuses-query';

// DOMAIN
import { MaritalStatusRepository } from '@/modules/onboarding/catalogs/marital-status/domain/repositories/marital-status-repository';
import { MaritalStatusStateManager } from '@/modules/onboarding/catalogs/marital-status/domain/state/marital-status-state-manager';

export default function setupMaritalStatusModule(container: Container) {
  const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<MaritalStatusRepository>(TYPES.MARITAL_STATUS_REPOSITORY)
    .to(with_dummies ? MaritalStatusDummyRepository : MaritalStatusHttpRepository)
    .inSingletonScope();

  container
    .bind<GetMaritalStatusesQuery>(TYPES.GET_MARITAL_STATUSES_QUERY)
    .to(GetMaritalStatusesQuery)
    .inSingletonScope();

  container
    .bind<MaritalStatusStateManager>(TYPES.MARITAL_STATUS_STATE_MANAGER)
    .to(MaritalStatusVueStateManager)
    .inSingletonScope();
}
