












import { Vue, Component } from 'vue-property-decorator';

@Component({ name: 'NotificationLoader' })
export default class NotificationLoader extends Vue {}
