import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import { BankAccountNumberEntity } from '../../domain/entities/bank-account-number-entity';
import { CreateBankAccountNumberRepository } from '../../domain/repositories/create-bank-account-number-repository';

@Injectable()
export default class CreateBankAccountNumberCommand extends Command<BankAccountNumberEntity> {
  @Inject(TYPES.ONBOARDING_CREATE_BANK_ACCOUNT_NUMBER_REPOSITORY)
  private readonly repository!: CreateBankAccountNumberRepository;

  internalExecute(bank_account_number: BankAccountNumberEntity): Promise<void> {
    return this.repository.create(bank_account_number);
  }
}
