/* eslint-disable max-len */

import TYPES from '../../../../../types';
import Inject from '@/modules/shared/domain/di/inject';
import OnboardingCreateCustomerAgreementCommand
  from '@/modules/onboarding/customer-agreement/application/commands/onboarding-create-customer-agreement-command';
import UpdateAddressCommand
  from '@/modules/onboarding/address/application/commands/update-address-command';
import CreatePhoneCommand
  from '@/modules/onboarding/phone/application/commands/create-phone-command';
import CreateCellPhoneCommand
  from '@/modules/onboarding/phone/application/commands/create-cell-phone-command';
import SaveOnboardingSectionServiceBase
  from './save-onboarding-section-service-base';
import { PromiseDto } from '../../../../domain/dtos/promise-dto';
import { SaveSectionResponseDto } from '../../../../domain/dtos/save-section-response-dto';
import { StoredPromiseDto } from '../../../../domain/dtos/stored-promise-dto';
import { CustomerPhoneEntity } from '@/modules/onboarding/phone/domain/entities';

export default class SavePersonalInformationSectionService extends SaveOnboardingSectionServiceBase {
  @Inject(TYPES.ONBOARDING_CREATE_CUSTOMER_AGREEMENT_COMMAND)
  private readonly onboardingCreateCustomerAgreementCommand!: OnboardingCreateCustomerAgreementCommand;

  @Inject(TYPES.UPDATE_ADDRESS_COMMAND)
  private readonly updateAddressCommand!: UpdateAddressCommand;

  @Inject(TYPES.CREATE_PHONE_COMMAND)
  private readonly createPhoneCommand!: CreatePhoneCommand;

  @Inject(TYPES.CREATE_CELL_PHONE_COMMAND)
  private readonly createCellPhoneCommand!: CreateCellPhoneCommand;

  async execute(): SaveSectionResponseDto {
    const personal_information_step = await this.get_on_boarding_step_query.execute('personal_information');

    const base_promises: Array<PromiseDto> = [
      {
        id: 'onboarding_create_customer_agreement_command-0',
        command: this.onboardingCreateCustomerAgreementCommand,
        data: personal_information_step.payload.agreements[0],
      },
      {
        id: 'onboarding_create_customer_agreement_command-1',
        command: this.onboardingCreateCustomerAgreementCommand,
        data: personal_information_step.payload.agreements[1],
      },
      {
        id: 'onboarding_create_customer_agreement_command-2',
        command: this.onboardingCreateCustomerAgreementCommand,
        data: personal_information_step.payload.agreements[2],
      },
      {
        id: 'update_address_command',
        command: this.updateAddressCommand,
        data: personal_information_step.payload.addresses[0].address,
      },
    ];

    if (personal_information_step.payload.phones) {
      personal_information_step.payload.phones.forEach((phone: CustomerPhoneEntity, index: number) => {
        base_promises.push({
          id: `create_phone_command-${index}`,
          command: this.createPhoneCommand,
          data: {
            id: phone.phone.id as string,
            number: phone.phone.number,
            name: phone.phone.name,
            country_code: phone.phone.country_code,
            customer_phone_id: phone.customer_phone_id,
            default: phone.customer_phone_default,
            confirmed: false,
            extension: '',
          },
        });
      });
    }

    base_promises.push({
      id: 'create_cell_phone_command-0',
      command: this.createCellPhoneCommand,
      data: {
        id: personal_information_step.payload.cellphones[0].cellphone.id as string,
        number: personal_information_step.payload.cellphones[0].cellphone.number,
        name: personal_information_step.payload.cellphones[0].cellphone.name,
        country_code: personal_information_step.payload.cellphones[0].cellphone.country_code,
        default: true,
        confirmed: false,
        customer_cellphone_id: personal_information_step.payload.cellphones[0].customer_cellphone_id,
      },
    });

    const promises = (await this.filteredPromises(base_promises, 'personal_information')).map(
      (item) => item.command.execute(item.data),
    );

    return new Promise((resolve) => {
      Promise.allSettled(promises)
        .then((results) => {
          const rejected_promises: Array<StoredPromiseDto> = [];
          results.forEach((item, index) => {
            if (item.status === 'rejected') {
              rejected_promises.push({
                id: base_promises[index].id,
                error: item.reason.message.toLowerCase().split(' ').join('_'),
              });
            }
          });

          resolve({ personal_information: rejected_promises });
        });
    });
  }
}
