import { Container } from 'inversify-props';
import TYPES from './types';
import UserDummyRepository from './infrastructure/repositories/current-user-dummy-repository';
import CurrentUserHttpRepository from './infrastructure/repositories/current-user-http-repository';
import GetCurrentUserQuery from './application/queries/get-current-user-query';
import { CurrentUserRepository } from './domain/repositories/current-user-repository';

const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

export default function setupCurrentUser(container: Container) {
  container
    .bind<CurrentUserRepository>(TYPES.CURRENT_USER_REPOSITORY)
    .to(
      with_dummies
        ? UserDummyRepository
        : CurrentUserHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetCurrentUserQuery>(TYPES.GET_CURRENT_USER_QUERY)
    .to(GetCurrentUserQuery)
    .inSingletonScope();
}
