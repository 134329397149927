import fixtures from './fixtures.json';

// DOMAIN
import { PromotorRepository } from '@/modules/onboarding/catalogs/promotor/domain/repositories/promotor-repository';
import { PromotorEntity } from '@/modules/onboarding/catalogs/promotor/domain/entities/promotor-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class PromotorDummyRepository implements PromotorRepository {
  searchPromotor(): Promise<Array<PromotorEntity>> {
    return Promise.resolve(fixtures);
  }
}
