import { SaveSectionResponseDto } from '../../../../domain/dtos/save-section-response-dto';
import Inject from '@/modules/shared/domain/di/inject';
import TYPES from '@/modules/onboarding/types';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';
import { PromiseDto } from '../../../../domain/dtos/promise-dto';
import { StoredPromiseDto } from '../../../../domain/dtos/stored-promise-dto';

export default abstract class SaveOnboardingSectionServiceBase {
  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  abstract execute(): SaveSectionResponseDto;

  async filteredPromises(promises: Array<PromiseDto>, section: string): Promise<Array<PromiseDto>> {
    const step = await this.get_on_boarding_step_query.execute('confirmation');
    const { errors } = step.payload;

    // eslint-disable-next-line
    // @ts-ignore
    if (typeof errors === 'object' && Object.keys(errors).length === 0) {
      return promises;
    }

    // eslint-disable-next-line
    // @ts-ignore
    const errors_in_section: Array<StoredPromiseDto> = errors[section];

    if (!errors_in_section.length) {
      return [];
    }

    const filtered_promises: Array<PromiseDto> = [];

    errors_in_section.forEach((item) => {
      const finded_promise = promises.find((promise) => promise.id === item.id);

      if (finded_promise) {
        filtered_promises.push(finded_promise);
      }
    });

    return filtered_promises;
  }
}
