import { Container } from 'inversify-props';
import TYPES from './types';
import CreateBankAccountNumberDummyRepository
  from './infrastructure/repositories/create-bank-account-number-repository/create-bank-account-number-dummy-repository';
import CreateBankAccountNumberHttpRepository
  from './infrastructure/repositories/create-bank-account-number-repository/create-bank-account-number-http-repository';
import CreateBankAccountNumberCommand from './application/commands/create-bank-account-number-command';
import { CreateBankAccountNumberRepository } from './domain/repositories/create-bank-account-number-repository';

const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

export default function setupBankAccountNumber(container: Container) {
  container
    .bind<CreateBankAccountNumberRepository>(TYPES.ONBOARDING_CREATE_BANK_ACCOUNT_NUMBER_REPOSITORY)
    .to(
      withDummies
        ? CreateBankAccountNumberDummyRepository
        : CreateBankAccountNumberHttpRepository,
    ).inSingletonScope();

  container
    .bind<CreateBankAccountNumberCommand>(TYPES.ONBOARDING_CREATE_BANK_ACCOUNT_NUMBER_COMMAND)
    .to(CreateBankAccountNumberCommand)
    .inSingletonScope();
}
